
export enum DomainType {
    LOCAL_HOST,
    DEV,
    PROD_ADMIN,
    PROD_CUST
}

export const getDomainType = (): DomainType => {
    const hostname = window.location.hostname;
    if (hostname.startsWith('localhost')) {
        return DomainType.LOCAL_HOST;
    } else if (hostname.startsWith('mapbiz.tmapadmin.com')) {
        return DomainType.PROD_ADMIN;
    } else if (hostname.startsWith('mapbiz.tmobiweb.com')) {
        return DomainType.PROD_CUST;
    }
    return DomainType.DEV;
}