import {get, post, patch, destroy} from '../api'
import _ from 'lodash'
import {userInfo, userRole} from "./loginAPI";

export type companyRequest = {
    coName: string;
    coContact: string;
    coEmail: string;
    coContractSt: Date;
    coContractEd: Date;
    coBlockFlag: string;
    coDistDates: string;
}

export type company = {
    coSeq: number;
    coName: string;
    coContact: string;
    coContractSt: Date;
    coContractEd: Date;
    coBlockFlag: string;
    regDate: Date;
    coDistDates: string;
}

export const queryKey_company = 'company';
export const getCompanies = async (underContract: boolean, searchKeyword: string) => {
    const response = await get<company[]>("/api/company", {
        params: {
            underContract: underContract,
            searchKeyword: searchKeyword
        }
    });
    return response.data;
}

export const getAllCompanies = async (searchKeyword: string) => {
    const response = await get<company[]>("/api/company", {
        params: {
            searchKeyword: searchKeyword
        }
    });
    return response.data;
}

export const getCompanyBySeq = async (coSeq: number) => {
    const response = await get<company>(`/api/company/${coSeq}`);
    return response.data;
}

// export const getCompanyById = async (coId: string) => {
//     const response = await get<company>(`/api/company/id/${coId}`);
//     return response.data;
// }

export const modifyCompany = async (coSeq:number, params: companyRequest) => {
    const response = await patch<companyRequest>(`/api/company/${coSeq}`, params );
    return response.data;
}

// export const modifyCurrentUser = async (coSeq:number, params: companyRequest) => {
//     const modifyParams = _.omit(params, ['coPasswd', 'authority', 'coId', 'coContractSt', 'coContractEd', 'coBlockFlag', 'coDistDates']);
//
//     const response = await patch<customerRequest>(`/api/company/${coSeq}`, modifyParams );
//     return response.data;
// }
//
// export const modifyCustomerPasswd = async (coSeq: number, params: updatePasswdRequest) => {
//     const response = await patch<updatePasswdRequest>(`/api/company/${coSeq}/password`, params);
//     return response.data;
// }
//
export const addCompany = async (params: companyRequest) => {
    const response = await post<companyRequest>('/api/company', params );
    return response.data;
}

export const deleteCompany = async (coSeq: number) => {
    const response = await destroy(`/api/company/${coSeq}`);
    return response.data;
}

export const getCountCompanyByName = async (coName: string) => {
    const response = await get<number>(`/api/company/count/name/${coName}`);
    return response.data;
}