import {get, post, patch, destroy} from '../api'
import _ from 'lodash'
import {userInfo, userRole} from "./loginAPI";

export type userRequest = {
    cuName: string;
    cuId: string;
    coSeq: number;
    cuPasswd: string;
    cuTel: string;
    cuEmail: string;
    cuBlockFlag: string;
    authority: userRole;
}

export type updatePasswdRequest = {
    password: string;
    newPassword: string;
    confirmPassword: string;
}

export const queryKey_users = 'users';
export const getUsers = async (searchKeyword: string) => {
    const response = await get<userInfo[]>("/api/user", {
        params: {
            searchKeyword: searchKeyword
        }
    });
    return response.data;
}

export const getUserBySeq = async (cuSeq: number) => {
    const response = await get<userInfo>(`/api/user/${cuSeq}`);
    return response.data;
}

export const getCustomerById = async (coId: string) => {
    const response = await get<userInfo>(`/api/user/id/${coId}`);
    return response.data;
}

export const modifyUser = async (cuSeq:number, params: userRequest) => {
    const modifyParams = _.omit(params, ['cuPasswd', 'authority', 'cuId']);

    const response = await patch<userRequest>(`/api/user/${cuSeq}`, modifyParams );
    return response.data;
}

export const modifyCurrentUser = async (cuSeq:number, params: userRequest) => {
    const modifyParams = _.omit(params, ['cuPasswd', 'authority', 'cuId', 'cuBlockFlag']);

    const response = await patch<userRequest>(`/api/user/${cuSeq}`, modifyParams );
    return response.data;
}

export const modifyUserPasswd = async (cuSeq: number, params: updatePasswdRequest) => {
    const response = await patch<updatePasswdRequest>(`/api/user/${cuSeq}/password`, params);
    return response.data;
}

export const addUser = async (params: userRequest) => {
    const response = await post<userRequest>('/api/user', params );
    return response.data;
}

export const deleteUser = async (cuSeq: number) => {
    const response = await destroy(`/api/user/${cuSeq}`);
    return response.data;
}

export const getCountUserById = async (cuId: string) => {
    const response = await get<number>(`/api/user/count/id/${cuId}`);
    return response.data;
}

export const deleteSecretKey = async (cuSeq: number) => {
    const response = await destroy(`/api/user/${cuSeq}/secret-key`);
    return response.data;
}