import {get, post} from '../api'

export interface FileInfo {
    fldSeq: number;
    name: string;
    size: number;
}

export const getFileList = (fldSeq: number) => {
    return get<FileInfo[]>(
        `/api/download/${fldSeq}`,
    );
}

export const getFileLists = (fldSeqList: number[]) => {
    return get<FileInfo[]>(
        `/api/download`,
        {
            params: {
                'fldSeqList': fldSeqList.join(',')
            }
        }
    );
}

export interface FileUrlRequestParam {
    fldSeq: number;
    filePath: string;
}

export interface FileUrlResponse {
    url: string;
    expiration: Date;
}

export const getFileUrl = (param: FileUrlRequestParam) => {
    return post<FileUrlResponse>(
        `/api/download/${param.fldSeq}/${param.filePath}`,
    );
}

export const sendDistMail = async (coSeq: number) => {
    const response = await post("/api/mail", {}, {params: {coSeq: coSeq}});
    return response.data;
}