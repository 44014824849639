import {Button, Checkbox, message, Row, Select, Space, Spin, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {useMutation, useQuery, useQueryClient} from "react-query";
import React, {useState} from "react";
import {
    coFolderRel,
    coFolderRequest,
    getCoFolderRel,
    insertCoDefFolder,
    insertCoFolder,
    queryKey_coFolderRel
} from "../api/folderAPI";
import {getCompanies, queryKey_company} from "../api/companyAPI";


interface DataType {
    key: string;
    fldSeq: number;
    fldName: string;
    useCnt: number; // 당월항목
    useDefCnt: number; // 계약항목
}

const DistributeCustFolderList = () => {
    const queryClient = useQueryClient();
    const [selCoSeq, setSelCoSeq] = useState(0);
    const [selSort, setSelSort] = useState(0);
    const {data: data_customers} = useQuery([queryKey_company, true, ''], () => getCompanies(true, ''));
    const {data: data_coFolderRel, isLoading} = useQuery([queryKey_coFolderRel, selCoSeq, selSort], () => getCoFolderRel(selCoSeq, selSort),
        {enabled: (data_customers != null)});

    const selectAllUseDef = (checked: boolean) => {
        queryClient.setQueryData([queryKey_coFolderRel, selCoSeq, selSort],
            data_coFolderRel?.map(value => {
                return {
                    ...value,
                    useDefCnt: checked? 1: 0
                }
            }))
    }

    const selectUseDef = (fldSeq: number, checked: boolean) => {
        queryClient.setQueryData([queryKey_coFolderRel, selCoSeq, selSort],
            data_coFolderRel?.map(value => {
                return (value.fldSeq === fldSeq) ? {
                    ...value,
                    useDefCnt: checked ? 1 : 0
                } : value
            }))
    }

    const selectAllUse = (checked: boolean) => {
        queryClient.setQueryData([queryKey_coFolderRel, selCoSeq, selSort],
            data_coFolderRel?.map(value => {
                return {
                    ...value,
                    useCnt: ((value.useDefCnt > 0) && checked)? 1: 0
                }
            }))
    }

    const selectUse = (fldSeq: number, checked: boolean) => {
        queryClient.setQueryData([queryKey_coFolderRel, selCoSeq, selSort],
            data_coFolderRel?.map(value => {
                return (value.fldSeq === fldSeq) ? {
                    ...value,
                    useCnt: checked? 1: 0
                } : value
            }))
    }

    const saveFolderMut = useMutation((data: coFolderRel[]) => {
        const request = data.filter(value => value.useDefCnt > 0)
            .map(value => {
                return {
                    coSeq: selCoSeq,
                    fldSeq: value.fldSeq
                }
            });
        return insertCoDefFolder(selCoSeq, request);
    }, {
        onMutate: (v) => {
            const data = v.filter(value => value.useCnt > 0)
                .map(value => {
                    return {
                        coSeq: selCoSeq,
                        fldSeq: value.fldSeq
                    }
                });

            if(data)
                addCoFolderMut.mutate(data);
        },
        onSuccess: () => {
            message.success('업데이트 완료');
            queryClient.invalidateQueries([queryKey_coFolderRel, selCoSeq, selSort]);
        },
        onError: (error) => {
            message.error('업데이트 실패');
            console.log(error);
        }
    });

    const addCoFolderMut = useMutation((request: coFolderRequest[]) => insertCoFolder(selCoSeq, request));

    const onCoFolderSaveAll = () => {
        if(selCoSeq === 0) {
            message.error('고객사를 선택해주세요.');
            return;
        }

        try{
            if(data_coFolderRel)
                saveFolderMut.mutate(data_coFolderRel);
        } catch (e) {
            console.log(e);
        }
    }

    const columns: ColumnsType<DataType> = [
        {
            align: 'center',
            title: 'No',
            dataIndex: 'key',
            width: '5%'
        },
        {
            title: '폴더',
            dataIndex: 'fldName',
            width: '70%'
        },
        {
            align: 'center',
            title: (<div>계약항목 전체선택<br/><Checkbox onChange={e => selectAllUseDef(e.target.checked)}/></div>),
            dataIndex: 'useDefCnt',
            render: (_, record) => {
                return <Checkbox checked={record.useDefCnt > 0} disabled={(record.useCnt > 0) && (record.useDefCnt > 0) } onChange={e => selectUseDef(record.fldSeq, e.target.checked)}/>
            }
        },
        {
            align: 'center',
            title: (<div>당월배포 전체선택<br/><Checkbox onChange={e => selectAllUse(e.target.checked)}/></div>),
            dataIndex: 'useCnt',
            render: (_, record) => {
                return <Checkbox checked={record.useCnt > 0} disabled={record.useDefCnt == 0} onChange={e => selectUse(record.fldSeq, e.target.checked)}/>
            }
        }
    ];

    const onSelectCustomer = (value: string) => {
        const coSeq = Number.parseInt(value);
        setSelCoSeq(coSeq);
    }

    const onSelectSort = (value: string) => {
        const sort = Number.parseInt(value);
        setSelSort(sort);
    }

    return (<div>
        <Row justify='end' style={{marginBottom:5}}>
            <Space wrap>
                <div style={{color:"gray"}}>필터 적용 시 데이터를 다시 읽어옵니다.</div>
                <Select style={{width:200}}
                        defaultValue='0'
                        showSearch
                        filterOption={(inputValue, option) =>
                            (option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase())
                        }
                        options={data_customers && [
                            {label: '고객사 선택', value: '0'},
                        ...(data_customers
                            .map((record) => {
                                return {
                                    label: record.coName,
                                    value: record.coSeq
                                }
                            }))]
                        }
                        onSelect={(value) => onSelectCustomer(value)}
                />
                <Select id='selectSort'
                        style={{width:160}}
                        defaultValue='0'
                        options={[
                            {label:'전체', value:'0'},
                            {label:'계약항목 보기', value:'3'},
                            {label:'당월배포 보기', value:'1'},
                            {label:'계약항목 비선택 보기', value:'4'},
                            {label:'당월배포 비선택 보기', value:'2'}
                        ]}
                        onSelect={(value) => onSelectSort(value)}
                />
            </Space>
        </Row>
        <Table
            loading={isLoading ? {indicator: <Spin/>} : false}
            size='small'
            columns={columns}
            pagination={false}
            dataSource={
                data_coFolderRel ?
                    (data_coFolderRel.sort((a, b) => a.fldName.localeCompare(b.fldName, 'euc-kr'))
                                .map((record, index) => {
                    return {
                        key: (index + 1).toString(),
                        fldSeq: record.fldSeq,
                        fldName: record.fldName,
                        useCnt: record.useCnt,
                        useDefCnt: record.useDefCnt
                    }
                })) : undefined}
            />
        <Row justify='end' style={{marginTop:5}}>
            <Space wrap>
                <Button type='primary' onClick={onCoFolderSaveAll}>계약항목 & 당월배포 저장</Button>
            </Space>
        </Row>
    </div>);
}

export default DistributeCustFolderList;