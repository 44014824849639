import React, {useState} from 'react';
import {Button, message, Popconfirm, Row, Select, Space, Spin, Table, Typography} from "antd";
import {CU_TYPE} from "../constants/company";
import type {ColumnsType} from "antd/es/table";
import {DeleteOutlined, EditOutlined, KeyOutlined, UserAddOutlined} from "@ant-design/icons";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {deleteSecretKey as deleteSecretKeyAPI, deleteUser as deleteUserAPI, getUsers, queryKey_users} from "../api/userAPI";
import Search from "antd/es/input/Search";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {authToCuType, queryKey_whoami, requestLogout, whoAmI} from "../api/loginAPI";
import {getAllCompanies, queryKey_company} from "../api/companyAPI";


// const searchCombo = (<Select
//     style={{width:80}}
//     defaultValue={'전체'}
//     options={(searCompoOptions).map(item => ({value: item, label: item}))}/>);

interface DataType {
    coName: string;
    cuSeq: string;
    cuType: string;
    cuName: string;
    regDate: string;
}

const UserList = () => {
    const queryClient = useQueryClient();
    const [selCoSeq, setSelCoSeq] = useState(0);
    const [searchKeyword, setSearchKeyword] = useState("");
    const {data: data_companies} = useQuery([queryKey_company, ''], () => getAllCompanies(''));
    const {data: data_users, isLoading} = useQuery([queryKey_users, searchKeyword], () => getUsers(searchKeyword),
        {enabled: (data_companies != null)});
    const {data: data_whoami} = useQuery([queryKey_whoami], () => whoAmI());

    const deleteUserMut = useMutation((cuSeq: number) => deleteUserAPI(cuSeq),{
        onSuccess: () => {
            queryClient.invalidateQueries([queryKey_users, searchKeyword]);
            message.success('업데이트 완료');
        },
        onError: (error) => {
            message.error('업데이트 실패');
            console.log(error);
        }
    })

    const deleteUser = (cuSeq: number) => {
        deleteUserMut.mutate(cuSeq);

    }

    const deleteSecretKeyMut = useMutation((cuSeq: number) => deleteSecretKeyAPI(cuSeq), {
        onSuccess: () => {
            message.success('업데이트 완료');
        },
        onError: (error) => {
            message.error('업데이트 실패');
            console.log(error);
        }
    } )

    const onLogout = () => {
        requestLogout();
        queryClient.invalidateQueries([queryKey_whoami]);
        window.location.replace('/');
    }

    const deleteSecretKey = (cuSeq: number) => {
        deleteSecretKeyMut.mutate(cuSeq);
        if(data_whoami && data_whoami.cuSeq == cuSeq) {
            onLogout();
        }
    }

    const columns: ColumnsType<DataType> = [
        {
            align: 'center',
            title: '구분',
            dataIndex: 'cuType',
            key: 'cuType',
            filters: CU_TYPE.map((item) => { return {text: item, value: item}}),
            onFilter: (value, record) => record.cuType === value.toString()
        },
        {
            align: 'center',
            title: '고객사',
            dataIndex: 'coName',
            key: 'coName'
        },
        {
            align: 'center',
            title: '이름',
            dataIndex: 'cuName',
            key: 'cuName'
        },
        {
            align: 'center',
            title: '등록일',
            dataIndex: 'regDate',
            key: 'regDate'
        },
        {
            align: 'center',
            title: 'OTP 초기화',
            key: 'resetOTP',
            render: (record) => (
                <Popconfirm title="OTP 키를 초기화 하시겠습니까?" onConfirm={() => deleteSecretKey(record.cuSeq)}>
                    <Typography.Link><KeyOutlined style={{ color: 'darkslategrey' }}/></Typography.Link>
                </Popconfirm>
            )
        },
        {
            align: 'center',
            title: '수정',
            key: 'modify',
            render: (record) => (
                <Link to={`/modifyUser/${record.cuSeq}`}><EditOutlined/></Link>
            )
        },
        {
            align: 'center',
            title: '삭제',
            key: 'delete',
            render: (record) => (
                <Popconfirm title="삭제하시겠습니까?" onConfirm={() => deleteUser(record.cuSeq)}>
                    <Typography.Link><DeleteOutlined/></Typography.Link>
                </Popconfirm>
            )
        }
    ]

    const onSearch = (value: string) => {
        setSearchKeyword(value);
    }

    const onSelectCompany = (value: string) => {
        const coSeq = Number.parseInt(value);
        setSelCoSeq(coSeq);
    }

    // if(userEditMode == UserEditMode.Add) {
    //    // return <AddCompany />;
    // } else if(userEditMode == UserEditMode.Edit) {
    //   //  return <AddCompany />;
    // }

    return (
        <div>
            <Row justify="space-between" style={{marginBottom:5}}>
                <Space>
                    <Select style={{width:200}}
                            defaultValue='0'
                            showSearch
                            options={data_companies && [
                                {label: '고객사 선택', value: '0'},
                                ...(data_companies
                                    .map((record) => {
                                        return {
                                            label: record.coName,
                                            value: record.coSeq
                                        }
                                    }))]
                            }
                            onSelect={(value) => onSelectCompany(value)}
                    />
                    <Search
                        allowClear
                        placeholder="이름 검색"
                        size="middle"
                        onSearch={onSearch}
                    />
                </Space>
                <Link to={'/modifyUser/new'}>
                    <Button
                        type="primary"
                        icon={<UserAddOutlined/>}>
                        사용자 추가
                    </Button>
                </Link>
            </Row>
            <Table
                loading={isLoading ? {indicator: <Spin/>} : false}
                size="middle"
                rowKey={(record) => record.cuSeq }
                columns={columns} dataSource={
                data_users && data_companies && data_users
                    .filter((value) => (selCoSeq !== 0) ? (value.coSeq === selCoSeq) : true)
                    .map((value) => {
                    const company = data_companies.find(com => com.coSeq === value.coSeq);
                return {
                    cuSeq: value.cuSeq.toString(),
                    cuType: authToCuType(value.authority),
                    coName: company ? company.coName : '관리자',
                    cuName: value.cuName,
                    regDate: dayjs(value.regDate).format('YYYY/MM/DD')
                }})}
            />
        </div>
    );
}

export default UserList;