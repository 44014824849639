import qs from 'qs';
import {post, get, destroy} from '../api'

export const requestLogin = async (username: String, password: String) => {
    return await post("/login",
        qs.stringify({username: username, password: password}),
        {
        withCredentials:true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}

export const requestLogout = async () => {
    return await destroy("/logout");
}

export type userRole = 'ROLE_USER' | 'ROLE_ADMIN' | 'ROLE_CO_ADMIN'
export interface userInfo {
    cuSeq:number;
    coSeq:number;
    cuName: string;
    cuId: string;
    cuPasswd: string;
    cuTel: string;
    cuEmail: string;
    regDate: Date;
    cuBlockFlag: string;
    authority: userRole;
}

export const userIsAdmin = (roles: userRole):boolean => roles === 'ROLE_ADMIN';
export const authToCuType = (authorities: userRole) => userIsAdmin(authorities) ? '관리자' : '고객사';
export const cuTypeToAuth = (coType: string):userRole => coType === '관리자' ? 'ROLE_ADMIN' : 'ROLE_USER';
export const queryKey_whoami = 'whoami';
export const whoAmI = async () => {
    const response = await get<userInfo>('/api/me');
    return response.data;
}