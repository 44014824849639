import {Footer} from "antd/es/layout/layout";
import React from "react";


const MainFooter = () => {
    return (
        <Footer style={{ textAlign: "left" }}>
            <hr/>
            티맵모빌리티(주)<br/>
            (04538) 서울 중구 삼일대로 343 대신파이낸스센터 12층<br/>
            COPYRIGHT T MAP MOBILITY CO., LTD. ALL RIGHTS RESERVED.
        </Footer>
    );
}

export default MainFooter;