import {useMutation, useQuery, useQueryClient} from "react-query";
import {authToCuType, queryKey_whoami, requestLogout, whoAmI} from "../api/loginAPI";
import {
    userRequest, modifyCurrentUser,
    modifyUserPasswd,
    updatePasswdRequest, queryKey_users, getUserBySeq, deleteSecretKey as deleteSecretKeyAPI
} from "../api/userAPI";
import React, {useState} from "react";
import {Button, Form, Input, message, Modal, Popconfirm, Space} from "antd";
import '../styles/addUser.css'
import {useNavigate} from "react-router-dom";
import {checkValidEmail} from "../libs/validateData";


const ModiCurrentUser = () => {

    const formItemLayout = {
        labelCol: {
            xs: { span: 12 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 12 },
            sm: { span: 16 },
        },
    };

    const passwdFormItemLayout = {
        labelCol: {
            xs: { span: 12 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 12 },
            sm: { span: 20 },
        },
    };

    const queryClient = useQueryClient();
    const [form] = Form.useForm();
    const [passwdForm] = Form.useForm();
    const navigate = useNavigate();
    const [userId, setUserId] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const {data: data_whoami} = useQuery([queryKey_whoami], () => whoAmI(), {
        onSuccess: (data) => {
            setUserId(data.cuSeq.toString());
        }
    });
    const {data: data_user} = useQuery([queryKey_users, userId], () => getUserBySeq(parseInt(userId)), {
        enabled: (data_whoami != null),
        onSuccess: (data) => {
            form.setFieldsValue({
                cuType: authToCuType(data.authority),
                cuName: data.cuName,
                cuTel: data.cuTel,
                cuId: data.cuId,
                cuPasswd: '',
                cuEmail: data.cuEmail,
            });
        }
    });

    const modifyUserMut = useMutation((request: userRequest) => modifyCurrentUser(Number.parseInt(userId), request), {
        onSuccess: () => {
            queryClient.invalidateQueries([queryKey_users, userId]);
            message.success('업데이트 완료');
            navigate('/mapDown');
        },
        onError: (error) => {
            message.error('업데이트 실패');
            console.log(error);
        }
    });

    const updatePasswdMut = useMutation((request: updatePasswdRequest) => modifyUserPasswd(Number.parseInt(userId), request), {
        onSuccess: () => {
            message.success('비밀번호 변경 완료');
            setOpenModal(false);
        },
        onError: (error) => {
            message.error('업데이트 실패');
            console.log(error);
        }
    })

    const updatePasswdHandler = (values: any) => {
        if(values.newPassword !== values.confirmPassword) {
            message.warning('비밀번호와 비밀번호 확인란의 값이 다릅니다.');
            return;
        }

        if(values.password == values.newPassword) {
            message.warning('현재 비밀번호와 새 비밀번호가 같습니다.');
            return;
        }

        updatePasswdMut.mutate({
            password: values.password,
            newPassword: values.newPassword,
            confirmPassword: values.confirmPassword
        });
    }

    const deleteSecretKeyMut = useMutation((cuSeq: number) => deleteSecretKeyAPI(cuSeq), {
        onSuccess: () => {
            message.success('업데이트 완료');
        },
        onError: (error) => {
            message.error('업데이트 실패');
            console.log(error);
        }
    } )

    const onLogout = () => {
        requestLogout();
        queryClient.invalidateQueries([queryKey_whoami]);
        window.location.replace('/');
    }

    const deleteSecretKey = (cuSeq: number) => {
        deleteSecretKeyMut.mutate(cuSeq);
        if(data_whoami && data_whoami.cuSeq == cuSeq) {
            onLogout();
        }
    }

    const onCancel = () => {
        navigate('/mapDown');
    }

    const submitHandler = (values: any) => {
        if(data_user) {
            modifyUserMut.mutate({
                cuName: data_user.cuName,
                cuId: data_user.cuId,
                coSeq: values.coSeq,
                cuPasswd: '',
                cuTel: values.cuTel,
                cuEmail: values.cuEmail,
                cuBlockFlag: data_user.cuBlockFlag,
                authority: data_user.authority,
            })
        }
    }

    const passwdRules =
        [
            {
                required: true,
                message: '비밀번호를 입력해주세요.'
            },
            {
                min: 8,
                max: 20,
                message: '비밀번호는 8자리에서 20자리 사이여야 합니다.'
            },
            {
                pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@!%*#?&]+/,
                message: '비밀번호는 영문,숫자,특수문자가 모두 포함되어야 합니다.'
            }
        ];

    return (
        <div>
        <Form
            {...formItemLayout}
            name="register"
            form={form}
            style={{maxWidth: 1000, minWidth: 1000}}
            scrollToFirstError
            colon
            onFinish={submitHandler}
        >
            <Form.Item
                name="cuType"
                label="구분"
            >
                {form.getFieldValue('cuType')}
            </Form.Item>
            <Form.Item
                name="cuName"
                label="이름"
            >
                {form.getFieldValue('cuName')}
            </Form.Item>
            <Form.Item
                name="cuTel"
                label="연락처"
                rules={[
                    {
                        required: true,
                        message: '연락처를 입력해주세요.'
                    }
                ]}
            >
                <Input className="AddUser-input"/>
            </Form.Item>
            <Form.Item
                name="cuId"
                label="아이디"
            >
                {form.getFieldValue('cuId')}
            </Form.Item>
            <Form.Item
                name="cuPasswd"
                label="비밀번호"
                >
                <Button onClick={() => setOpenModal(true)}>비밀번호 변경</Button>
            </Form.Item>
            <Form.Item
                name="cuSecretKey"
                label="2차 인증"
            >
                <Popconfirm title="OTP 키를 초기화 하시겠습니까?" onConfirm={() => deleteSecretKey(parseInt(userId))}>
                    <Button>OTP 키 삭제</Button>
                </Popconfirm>
            </Form.Item>
            <Form.Item
                name="cuEmail"
                label="이메일"
                required
                rules={[
                    {
                        validator: (rule, value) => {
                            const errmsg = checkValidEmail(value);
                            if(errmsg.length > 0 ) return Promise.reject(new Error(errmsg));
                            return Promise.resolve();
                        }
                    }
                ]}
            >
                <Input.TextArea className="AddCompany-text-area"/>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
                <Space>
                    <Button type="primary" htmlType="submit" >
                        수정
                    </Button>
                    <Button onClick={onCancel}>
                        취소
                    </Button>
                </Space>
            </Form.Item>
        </Form>
        <Modal
            open={openModal}
            title='비밀번호 변경'
            onCancel={() => setOpenModal(false)}
            footer={null}
        >
            <Form {...passwdFormItemLayout}
                  form={passwdForm}
                  onFinish={updatePasswdHandler}
            >
                <Form.Item
                    name='password'
                    label='현재 비밀번호'
                    rules={[{
                        required: true,
                        message: '현재 비밀번호를 입력해주세요..'
                    }]}
                >
                    <Input.Password style={{width: '50%', marginLeft:20}}/>
                </Form.Item>
                <Form.Item
                    name='newPassword'
                    label='신규 비밀번호'
                    rules={passwdRules}
                >
                    <Input.Password style={{width: '50%', marginLeft:20}}/>
                </Form.Item>
                <Form.Item
                    name='confirmPassword'
                    label='비밀번호 확인'
                    rules={[{
                        required: true,
                        message: '비밀번호를 다시 한번 입력해주세요.'
                    }]}
                >
                    <Input.Password style={{width: '50%', marginLeft:20}}/>
                </Form.Item>
                <Form.Item style={{margin:0}} wrapperCol={{ offset: 10, span: 16 }}>
                    <Space>
                        <Button type="primary" htmlType="submit" >
                            수정
                        </Button>
                        <Button onClick={() => setOpenModal(false)}>
                            취소
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    </div>
    );
}

export default ModiCurrentUser;