import React, {useEffect, useState} from "react";
import {Button, Col, message, Modal, notification, Progress, Row, Space, Spin, Table, Tag} from "antd";
import {FileInfo, getFileLists} from "../api/downloadAPI";
import prettyBytes from "pretty-bytes";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {
    downloadFile,
    DownloadStatus, initialize, requestCanceling, requestPausing,
    selectFileDownloadState,
    setDownloadItems,
    setRootDirHandle,
} from "../app/ducks/downloadFile.duck";
import scrollIntoView from "scroll-into-view";

export interface Folder {
    fldSeq: number;
    fldName: string;
}

type Props = {
    open: boolean;
    folderList: Folder[];
    onOk: () => void;
    onCancel: () => void;
}

const columns = [
    {
        title: '파일명',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '파일크기',
        dataIndex: 'size',
        key: 'size',
        render: (size: number) => {
            return prettyBytes(size, {minimumFractionDigits: 2, maximumFractionDigits: 2});
        }
    },
    {
        title: '상태',
        dataIndex: 'status',
        key: 'status',
        render: (status: DownloadStatus) => {
            return (
                <Tag color={getStatusColor(status)}>
                    {getStatusString(status)}
                </Tag>
            );
        }
    },
];

function getStatusColor(status: DownloadStatus) {
    switch (status) {
        case DownloadStatus.READY:
            return "default";
        case DownloadStatus.DOWNLOADING:
            return "blue";
        case DownloadStatus.COMPLETED:
            return "green";
        case DownloadStatus.FAILED:
            return "red";
        case DownloadStatus.PAUSED:
            return "yellow";
        default:
            return "gray";
    }
}

function getStatusString(status: DownloadStatus) {
    switch (status) {
        case DownloadStatus.READY:
            return "대기중";
        case DownloadStatus.DOWNLOADING:
            return "다운로드중";
        case DownloadStatus.COMPLETED:
            return "완료";
        case DownloadStatus.FAILED:
            return "오류";
        case DownloadStatus.PAUSED:
            return "일시중지";
        case DownloadStatus.CANCELED:
            return "취소";
        default:
            return "???";
    }
}

function handleTabClose(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = "";
    return "";
}

function handleGoBack() {
    window.history.pushState(null, document.title, window.location.href);
}

function preventPageClosed(prevent: boolean) {
    if (prevent) {
        window.addEventListener('beforeunload', handleTabClose);

        if (window.history.state != null) {
            window.history.pushState(null, document.title, window.location.href);
            window.addEventListener('popstate', handleGoBack);
        }
    } else {
        window.removeEventListener('beforeunload', handleTabClose);

        if (window.history.state == null) {
            window.history.back();
        }
        window.removeEventListener('popstate', handleGoBack);
    }
}

const DownloadDialog = ({open, folderList, onCancel}: Props) => {
    const dispatch = useAppDispatch();
    const downloadState = useAppSelector(selectFileDownloadState);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isRequesting, setIsRequesting] = useState<boolean>(false);

    const [messageApi, contextHolder] = message.useMessage();
    const [notificationApi, notificationContextHolder] = notification.useNotification();

    const popupErrorMessage = (message: string, duration: number=0) => {
        notificationApi['error']({
            message: "다운로드 오류",
            description: message,
            placement: "top",
            duration: duration,
        });
    }

    const popupWarningMessage = (message: string, duration: number=5) => {
        messageApi.warning(message, duration).then(() => {
        });
    }

    useEffect(() => {
        if (downloadState.errorMessage) {
            popupErrorMessage(downloadState.errorMessage);
        }
    }, [downloadState.errorMessage]);

    useEffect(() => {
        if (downloadState.status === DownloadStatus.COMPLETED || downloadState.status === DownloadStatus.CANCELED) {
            preventPageClosed(false);
        }
    }, [downloadState]);

    useEffect(() => {
        scrollToRow(downloadState.currentItemIndex);
    }, [downloadState.currentItemIndex]);

    useEffect(() => {
        setTimeout(() => {
            setIsRequesting(false);
        }, 1000);

        if (downloadState.status === DownloadStatus.CANCELED) {
            onCancel();
        }
    }, [downloadState.status]);

    const getFileListsWithParam = async (folderList: Folder[]) => {
        return {r: await getFileLists(folderList.flatMap(r => r.fldSeq))};
    }

    useEffect(() => {
        dispatch(initialize());

        setIsLoading(true);
        getFileListsWithParam(folderList)
            .then(res => {
                const fileInfos = res.r.data
                    folderList
                        .forEach((value) => {
                            if(!fileInfos.some((fileInfo) => ("/" + fileInfo.name).startsWith(value.fldName))) {
                                popupWarningMessage(`폴더가 비어있습니다. ${value.fldName}`);
                            }
                        })

                const downloadItems =
                    fileInfos
                    .map((fileInfo, index) => {
                        return {
                            ...(fileInfo as FileInfo),
                            key: index,
                            status: DownloadStatus.READY,
                            fileHandle: null,
                        }
                    });

                dispatch(setDownloadItems(downloadItems));
            })
            .catch(err => {
                popupErrorMessage(err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [folderList]);

    const scrollToRow = (index: number) => {
        const row = document.querySelector<HTMLElement>(`.download-item-${index}`);
        if (row) {
            scrollIntoView(row, {
                align: {
                    top: 0,
                },
            });
        }
    }

    const handleCancel = () => {
        if (downloadState.status !== DownloadStatus.COMPLETED && downloadState.status !== DownloadStatus.READY) {
            Modal.confirm({
                title: '다운로드가 완료되지 않았습니다. 종료하시겠습니까?',
                style: {
                    top: 200,
                },
                onOk() {
                    dispatch(requestCanceling());
                },
                onCancel() {
                }
            });
        } else {
            dispatch(requestCanceling());
        }
    };

    const getDirHandle = async () => {
        if (downloadState.rootDirHandle == null) {
            const handle = await showDirectoryPicker({mode: 'readwrite'});
            dispatch(setRootDirHandle(handle));
            return handle
        } else {
            return downloadState.rootDirHandle;
        }
    }

    const startDownload = () => {
        getDirHandle()
            .then(handle => {
                preventPageClosed(true);
                dispatch(downloadFile({handle: handle!}));
            })
            .catch((err) => {
                popupErrorMessage(err.message);
            });
    }

    const pauseDownload = () => {
        dispatch(requestPausing());
    }

    const getDownloadButtonString = (): string => {
        if (downloadState.isPausing) {
            return "중지중"
        } else if (downloadState.isCanceling) {
            return "취소중"
        }

        switch (downloadState.status) {
            case DownloadStatus.DOWNLOADING:
                return "다운로드 중지";
            case DownloadStatus.PAUSED:
            case DownloadStatus.FAILED:
                return "다운로드 재개";
            case DownloadStatus.COMPLETED:
                return "다운로드 완료";
            default:
                return "다운로드 시작";
        }
    }

    return (
        <>
            {contextHolder}
            {notificationContextHolder}
            <Modal
                title="다운로드"
                width={"50vw"}
                open={open}
                closable={false}
                keyboard={false}
                okButtonProps={{disabled: true}}
                cancelButtonProps={{disabled: true}}
                onCancel={() => handleCancel()}
                footer={[
                    <Button
                        key="1"
                        type="primary"
                        loading={isRequesting || downloadState.isPausing}
                        disabled={
                            isLoading ||
                            isRequesting ||
                            downloadState.isPausing ||
                            downloadState.isCanceling ||
                            downloadState.items.length == 0 ||
                            downloadState.status === DownloadStatus.COMPLETED ||
                            downloadState.status === DownloadStatus.CANCELED
                        }
                        onClick={() => {
                            if (!isRequesting) {
                                setIsRequesting(true);
                                if (downloadState.status === DownloadStatus.DOWNLOADING) {
                                    pauseDownload();
                                } else if (downloadState.status !== DownloadStatus.CANCELED) {
                                    startDownload();
                                }
                            }
                        }}
                    >
                        {getDownloadButtonString()}
                    </Button>,
                    <Button
                        disabled={downloadState.isCanceling || downloadState.isPausing || isRequesting}
                        key="2"
                        onClick={() => handleCancel()}
                    >
                        종료
                    </Button>
                ]}
            >
                <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                    <Table
                        size='large'
                        columns={columns}
                        dataSource={downloadState.items}
                        pagination={false}
                        bordered
                        scroll={{x: 'max-content', y: 400}}
                        rowClassName={(record, index) => `download-item-${index}`}
                        loading={isLoading || downloadState.isCanceling ? {indicator: <Spin/>} : false}
                        footer={() => (
                            <Col>
                                <Row justify="space-between">
                                    <Col>
                                        파일 수 {downloadState.items.filter(item => item.status === DownloadStatus.COMPLETED).length} / {downloadState.items.length}
                                    </Col>
                                    <Col>
                                        {`다운로드 용량 
                                        ${prettyBytes(downloadState.totalReadBytes, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                        / 
                                        ${prettyBytes(downloadState.totalSize, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    />
                    <div>
                        <Row>
                            <Col flex="120px">
                                현재 파일
                            </Col>
                            <Col flex="auto">
                                <Progress percent={downloadState.currentProgress}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col flex="120px">
                                전체 파일
                            </Col>
                            <Col flex="auto">
                                <Progress percent={downloadState.totalProgress}/>
                            </Col>
                        </Row>
                    </div>
                </Space>
            </Modal>
        </>
    );
}

export default DownloadDialog;