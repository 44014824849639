import React from 'react';
import './App.css';
import {BrowserRouter} from "react-router-dom";
import Login from "./pages/login";
import Main from "./pages/main";
import {queryKey_whoami, userIsAdmin, whoAmI} from "./api/loginAPI";
import {useQuery} from "react-query";
import {Spin} from "antd";
import {DomainType, getDomainType} from "./libs/checkDomain";

function App() {

    const {data, isLoading} = useQuery([queryKey_whoami], () => whoAmI(), {
        retry: false
    });

    if(isLoading) {
        return (
            <div style={{width: '100vw', height: '100vh', alignItems: 'center', display:'flex', justifyContent: 'center'}}>
                <Spin />
            </div>);
    }

    if(!data) {
        return <Login/>
    }

    const domainType = getDomainType();

    return (
              <BrowserRouter>
                <Main userId={data.cuName} isAdmin={userIsAdmin(data.authority) && (domainType !== DomainType.PROD_CUST)}/>
              </BrowserRouter>

    );
}

export default App;
