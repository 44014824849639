
import React, {useEffect, useState} from 'react';
import {UserOutlined, LockOutlined, KeyOutlined} from '@ant-design/icons';
import {Button, Form, Image, Input, message, Modal, Space} from "antd";
import imgLogo from '../assets/TMAP_Black.svg';
import '../styles/login.css'
import {queryKey_whoami, requestLogin} from "../api/loginAPI";
import {useMutation, useQueryClient} from "react-query";
import {getOtpQRCode, otpVerifyRequest, verifyOtp} from "../api/otpAPI";
import {DomainType, getDomainType} from "../libs/checkDomain";

enum LoginStep {
    addOTP,
    checkOTP,
    login
}

const getTitle = (): string => {
    const domainType = getDomainType();
    if(domainType === DomainType.PROD_CUST) {
        return '맵 배포 시스템';
    }
    else {
        return '맵 배포 시스템 ADMIN';
    }
}

const INITIAL_TIMER_SECONDS = 180;

function useTimer(initialSeconds: number) {
    const [timer, setTimer] = useState(initialSeconds);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (isActive) {
            interval = setInterval(() => {
                setTimer((prevTimer: number) => (prevTimer > 0 ? prevTimer - 1 : 0));
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isActive]);

    const resetTimer = () => {
        setTimer(initialSeconds);
        setIsActive(true);
    };

    const stopTimer = () => {
        setIsActive(false);
    };

    return { timer, resetTimer, stopTimer };
}

const Login = () => {

    const [loginStep, setLoginStep] = useState(LoginStep.login);
    const [qrCode, setQRCode] = useState('');
    const [otpCode, setOtpCode] = useState('');
    const [username, setUsername] = useState('');
    const { timer, resetTimer, stopTimer } = useTimer(INITIAL_TIMER_SECONDS);
    const queryClient = useQueryClient();

    useEffect(() => {
        if(timer == 0) {
            message.error('입력 시간이 만료되었습니다.');
            handleCancel();
        }
    }, [timer]);

    const handleOk = () => {
        setLoginStep(LoginStep.login);
        stopTimer();
    };

    const handleCancel = () => {
        setLoginStep(LoginStep.login);
        stopTimer();
    };

    const getQRCodeMut = useMutation((cuId: string) => getOtpQRCode(cuId), {
        onSuccess: (data: string) => {
           if(data && data.length > 0) {
               setQRCode(data);
           }
        }
    })

    const verifyOtpMut = useMutation((req: otpVerifyRequest) => verifyOtp(req), {
        onSuccess: (_) => {
            queryClient.invalidateQueries([queryKey_whoami]);
        },
        onError: (_) => {
            message.error('otp key 인증이 실패하였습니다.');
        }
    })

    const loginSubmitHandler = (values: any) => {
        requestLogin(values.username, values.password)
            .then(value => {
                if(value.data && value.data.length > 0) {
                    getQRCodeMut.mutate(values.username);
                    setLoginStep(LoginStep.addOTP);
                    setUsername(values.username);
                    setOtpCode('');
                    resetTimer();
                } else {
                    setLoginStep(LoginStep.checkOTP);
                    setUsername(values.username);
                    setOtpCode('');
                    resetTimer();
                }
                // BE에서 처리함
                //const clientIP = data_client ? data_client.IPv4 : '0,0,0,0';
                //insertAccessLogMut.mutate({coId: values.username, ip: clientIP});
            })
            .catch(error => {
                if(error.response.status === 401) {
                    message.error('로그인 정보가 올바르지 않습니다.');
                } else {
                    message.error('로그인을 실패하였습니다. 관리자에게 문의하세요.');
                }
                return;
            });
    }

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };

    return (
        <div className="Login-form-container">
            <Form
                name="login"
                className="Login-form"
                initialValues={{remember: true}}
                onFinish={loginSubmitHandler}
            >
                <Form.Item>
                    <Image src={imgLogo} width={200} preview={false}/>
                    <div className="Login-form-textBox">
                        <h3> {getTitle()} </h3>
                    </div>
                    <hr/>
                    <div className="Login-form-guide-textBox">
                        아이디와 비밀번호를 입력하세요.
                    </div>
                </Form.Item>
                <Form.Item
                    name="username"
                    rules={[{required: true, message: '아이디를 입력해주세요.'}]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon"/>}
                           placeholder="Username"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{required: true, message: '비밀번호를 입력해주세요.'}]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="Login-form-button">
                        Log in
                    </Button>
                </Form.Item>
            </Form>
            <Modal
                open={loginStep === LoginStep.addOTP && qrCode.length > 0}
                title={<h2>OTP 인증 설정하기</h2>}
                onOk={handleOk}
                onCancel={handleCancel}
                width={"30%"}
                footer={
                <>
                    <br/>
                    <Button key='submit' type='primary' onClick={() => verifyOtpMut.mutate({cuId: username, otp: otpCode})}>
                        등록 완료
                    </Button>
                </>
                }
            >
                <p>Google OTP 앱을 이용하여 2단계 인증을 설정합니다.</p>
                <p>1. 휴대폰에 Google Authenticator 을 설치하세요.</p>
                <p>2. 앱을 실행한 뒤, 아래의 QR코드를 스캔하세요.</p>
                <p>이 창이 닫힌 후에는 OTP Code 값을 입력해야 로그인이 가능합니다.</p>
                <div className="qr-container">
                <div className="qr-box">
                    <Image src={"data:image/png;base64," + qrCode}
                           preview={false}/>
                </div>
                </div>
                <p>3. 앱에서 생성된 6자리 숫자를 입력하세요.</p>
                <div className="input-section">
                    <Space>
                        <b>OTP 인증 코드</b>
                        <Input
                            id='otpinput'
                            prefix={<KeyOutlined className="site-form-item-icon"/>}
                            placeholder="otpkey"
                            value={otpCode}
                            onChange={e => setOtpCode(e.target.value)}
                        />
                        <div style={{margin: '5px', color: 'orangered', fontSize: 15}}>
                            {formatTime(timer)}
                        </div>
                    </Space>
                </div>
            </Modal>
            <Modal
                open={loginStep === LoginStep.checkOTP}
                title={<h2>OTP 인증</h2>}
                onOk={handleOk}
                onCancel={handleCancel}
                width={"30%"}
                footer={
                    <>
                        <br/>
                        <Button key='submit' type='primary' onClick={() => verifyOtpMut.mutate({cuId: username, otp: otpCode})}>
                            로그인
                        </Button>
                    </>
                }
            >
                <p>OTP 인증코드 6자리 숫자를 입력해주세요.</p>
                <p>인증정보 초기화가 필요하신 경우 관리자에게 문의 바랍니다.</p>
                <div className="input-section">
                    <Space>
                        <b>OTP 인증 코드</b>
                        <Input
                            id='otpinput'
                            prefix={<KeyOutlined className="site-form-item-icon"/>}
                            placeholder="otpkey"
                            value={otpCode}
                            onChange={e => setOtpCode(e.target.value)}
                        />
                        <div style={{margin: '5px', color: 'orangered', fontSize: 15}}>
                            {formatTime(timer)}
                        </div>
                    </Space>
                </div>

            </Modal>
        </div>
    );
}

export default Login;
