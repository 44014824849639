

export const checkValidEmail = (email: string) : string => {
    if(email.length === 0)
        return '이메일을 입력해주세요.';

    if(email === ';' || email.trim().endsWith(';') || email.trim().startsWith(';'))
        return '이메일 목록의 처음과 끝은 ;가 될 수 없습니다.';

    const regEmail = /([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    const emailList = email.split(';').map(value => value.trim());
    for (let i = 0; i < emailList.length; i++) {
        if (!regEmail.test(emailList[i])) {
            return emailList[i] + ' 은 이메일 형식이 아닙니다.';
        }
        if (emailList.indexOf(emailList[i]) !== i) {
            return emailList[i] + ' 이 중복되었습니다.';
        }
    }

    return '';
}