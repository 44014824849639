import {get, patch} from '../api'

export const getOtpQRCode = async (cuId: string) => {
    const response = await get<string>(`/api/otp`, {
        params:{
            cuId: cuId
        }
    });
    return response.data;
}

export type otpVerifyRequest = {
    cuId:string;
    otp:string;
}

export const verifyOtp = async (request: otpVerifyRequest) => {
    const response = await patch<otpVerifyRequest>(`/api/otp/verify`, request);
    return response.data;
}