import React from 'react';
import {Image, MenuProps, Menu} from "antd";
import imgLogo from "../assets/TMAP MOBILITY_White.svg";
import Sider from "antd/es/layout/Sider";
import {Link, useLocation} from "react-router-dom";
import {DownloadOutlined, FolderOutlined, HistoryOutlined, HomeOutlined, UserOutlined} from "@ant-design/icons";

import '../styles/main.css'

type MainMenuItem = Required<MenuProps>["items"][number];

function getMainMenuItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MainMenuItem[]
): MainMenuItem {
    return {
        key,
        icon,
        children,
        label
    } as MainMenuItem;
}

const adminItems: MainMenuItem[] = [
    getMainMenuItem(<Link to="/companyList">고객사 관리</Link>, "companyList", <HomeOutlined />),
    getMainMenuItem(<Link to="/userList">유저 관리</Link>, "userList", <UserOutlined />),
    getMainMenuItem("배포 데이터 관리", "distributeList", <FolderOutlined />, [
        getMainMenuItem(<Link to="/distributeFolderList">배포 폴더 설정</Link>, "distributeFolderList"),
        getMainMenuItem(<Link to="/distributeCustFolderList">고객사별 배포 폴더 설정</Link>, "distributeCustFolderList"),
        getMainMenuItem(<Link to="/distributeSetting">배포 설정</Link>, "distributeSetting"),
        getMainMenuItem(<Link to="/distributeHistory">배포 이력 조회</Link>, "distributeHistory")
    ]),
    getMainMenuItem(<Link to="/mapDown">배포 맵 다운로드</Link>, "mapDown", <DownloadOutlined />),
    getMainMenuItem(<Link to="/accessLog">접속 관리</Link>, "accessLog", <HistoryOutlined />)
];

const items: MainMenuItem[] = [
    getMainMenuItem(<Link to="/mapDown">배포 맵 다운로드</Link>, "mapDown", <DownloadOutlined />)
];

interface MainMenuProps {
    isAdmin: boolean
}

const MainMenu = (props: MainMenuProps) => {

    const location = useLocation();

    const toMenuKey = (pathname: string) : string => {
        if(pathname.includes('modifyCompany')) {
            return 'companyList';
        } else if(pathname.includes('modifyUser')) {
            return 'userList';
        } {
            return pathname.replace('/', '')
        }
    }

    if(props.isAdmin) {
        return (
            <Sider width={230} trigger={null} collapsible={false}>
                <div className="Main-menu-logo">
                    <Image src={imgLogo} preview={false}/>
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={[toMenuKey(location.pathname)]}
                    defaultOpenKeys={['distributeList']}
                    items={adminItems}
                />
            </Sider>
        );
    }

    return (
        <Sider width={230} trigger={null} collapsible={false}>
            <div className="Main-menu-logo">
                <Image src={imgLogo} preview={false}/>
            </div>
            <Menu
                theme="dark"
                mode="inline"
                selectedKeys={[toMenuKey(location.pathname)]}
                defaultSelectedKeys={['mapDown']}
                items={items}
            />
        </Sider>
    );
}

export default MainMenu;