import React, {useState} from "react";
import {Button, message, Row, Select, Space, Spin, Table, Typography} from "antd";
import {CheckOutlined, DownloadOutlined} from "@ant-design/icons";
import {useQuery} from "react-query";
import {getMapDownList, queryKey_mapDownList} from "../api/folderAPI";
import {ColumnsType} from "antd/es/table";
import DownloadDialog, {Folder} from "./downloadDialog";
import {queryKey_whoami, userIsAdmin, whoAmI} from "../api/loginAPI";
import {getCompanies, queryKey_company} from "../api/companyAPI";

interface DataType {
    key: string;
    fldSeq: number;
    fldName: string;
    useCnt: number;
    checked: boolean;
}

const MapDown = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selCoSeq, setSelCoSeq] = useState(0);
    const [open, setOpen] = useState(false);
    const [enableDown, setEnableDown] = useState(false);
    const [folderList, setFolderList] = useState<Folder[]>([]);
    const {data: data_whoami} = useQuery([queryKey_whoami], () => whoAmI(), {
        onSuccess: (data) => {
            if(!userIsAdmin(data.authority)) {
                onSelectCustomer(data.coSeq.toString());
            }
        }
    });
    const {data: data_customers} = useQuery([queryKey_company, true, ''], () => getCompanies(true, ''),
        {enabled: data_whoami && userIsAdmin(data_whoami.authority)});
    const {data: data_mapDown, isLoading} = useQuery([queryKey_mapDownList, selCoSeq], () => getMapDownList(selCoSeq), {
        onSuccess: data => {
            setEnableDown(data.some(value => value.useCnt > 0));
        }
    });

    const onSelectCustomer = (value: string) => {
        const coSeq = Number.parseInt(value);
        setSelCoSeq(coSeq);
        setSelectedRowKeys([]);
    }

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[]) => {
          setSelectedRowKeys(selectedRowKeys);
        },
        selectedRowKeys: selectedRowKeys
    }

    const handleOk = () => {
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const mapDown = (fldList: {fldSeq: number, fldName: string}[]) => {
        setFolderList(fldList);

        setOpen(true);
    }

    const onDownAll = () => {
        if(data_mapDown) {
            mapDown(data_mapDown.map(value => {
                return {fldSeq: value.fldSeq, fldName: value.fldName};
            }));
        }
    }

    const onDownCheck = () => {
        if(selectedRowKeys.length === 0) {
            message.warning("선택된 항목이 없습니다.");
            return;
        }

        if(data_mapDown) {
            const folders = data_mapDown.filter(value => selectedRowKeys.some(fldSeq => fldSeq === value.fldSeq))
                .map(value => {
                    return {fldSeq: value.fldSeq, fldName: value.fldName};
                });

            mapDown(folders);
        }
    }

    const columns: ColumnsType<DataType> = [
        {
            align: 'center',
            title: 'No',
            dataIndex: 'key',
            width: '5%'
        },
        {
            title: '폴더',
            dataIndex: 'fldName',
            width: '70%'
        },
        {
            align: 'center',
            title: '다운로드',
            dataIndex: 'useCnt',
            render: (_, record) => {
                return record.useCnt > 0 ? <Typography.Link onClick={() => mapDown([{fldSeq: record.fldSeq, fldName: record.fldName}])}><DownloadOutlined/></Typography.Link> : <div/>
            }
        }
    ]

    return (
        <div>
            {
                (data_whoami && userIsAdmin(data_whoami.authority)) ?
                    <Select style={{width:200}}
                            defaultValue={'0'}
                            options={
                                (data_whoami && userIsAdmin(data_whoami.authority)) ? (
                                        data_customers && [
                                            {label: '고객사 선택', value: '0'},
                                            ...(data_customers
                                                .map((record) => {
                                                    return {
                                                        label: record.coName,
                                                        value: record.coSeq
                                                    }
                                                }))]) :
                                    (data_whoami && data_customers && [
                                        ...(data_customers.filter((value) => data_whoami.coSeq == value.coSeq))
                                            .map((record) => {
                                                return {
                                                    label: record.coName,
                                                    value: record.coSeq
                                                }
                                            })
                                    ])
                            }
                            onSelect={(value) => onSelectCustomer(value)}/> : <div/>
            }
        <Table style={{marginTop:5}}
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection
                }}
               loading={isLoading ? {indicator: <Spin/>} : false}
               pagination={false}
               size="small"
               columns={columns}
               rowKey="fldSeq"
               dataSource={
                    data_mapDown ?
                        (data_mapDown.sort((a, b) => a.fldName.localeCompare(b.fldName, 'euc-kr'))
                            .map((record, index) => {
                            return {
                                key: (index + 1).toString(),
                                fldSeq: record.fldSeq,
                                fldName: record.fldName,
                                useCnt: record.useCnt,
                                checked: false
                            }
                        })) : undefined}
        />
            <hr/>
            <li>시스템 기본 경로 (C:\, D:\, /, 내문서, 다운로드, 바탕화면 등)에는 지도를 받을 수 없습니다.</li>
            <li>맵 다운로드 경로 오류 발생 시 하위 폴더를 하나 생성하신 후 해당 폴더에 다운로드 해주세요.</li>
        <Row justify='end' style={{marginTop:5}}>
            <Space wrap>
                <Button type='primary' style={{backgroundColor:"coral"}} disabled={!enableDown} onClick={() => onDownAll()}>전체 다운로드</Button>
                <Button type='primary' icon={<CheckOutlined/>} disabled={!enableDown} onClick={() => onDownCheck()} >선택 항목 다운</Button>
            </Space>
        </Row>
        <DownloadDialog
            open={open}
            folderList={folderList}
            onOk={handleOk}
            onCancel={handleCancel}
        />
        </div>
    )
}

export default MapDown;