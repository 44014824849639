import {get, post} from "./index";


export type accessLog = {
    seq:number;
    coName:string;
    cuName:string;
    ip:string;
    regDate:Date;
}

export type accessLogRequest = {
    coId:string;
    ip: string;
}

export const queryKey_accessLog = 'accessLog';

export const getAccessLogPaging = async (searchValue: string, searchStDate: string, searchEdDate: string, pageSize: number, curPage: number) => {
    const response = await get<accessLog[]>("/api/access_log/page",
        {params: {searchValue: searchValue, searchStDate: searchStDate, searchEdDate: searchEdDate, pageSize: pageSize, curPage: curPage}});
    return response.data;
}

export const getCountAccessLog = async (searchValue: string, searchStDate: string, searchEdDate: string) => {
    const response = await get<number>("/api/access_log/count",
        {params: {searchValue: searchValue, searchStDate: searchStDate, searchEdDate: searchEdDate}});
    return response.data;
}

export const insertAccessLog = async (params: accessLogRequest) => {
    const response = await post<accessLogRequest>("/api/access_log",  {}, {
        params: { coId: params.coId, ip: params.ip}});
    return response.data;
}