import {destroy, get, post} from '../api'

export type coSet = {
    coSeq: number;
    coName: string;
    distDate: string;
    distStateText: string;
    regDate: Date;
    searchTypeCount: number;
}

export const queryKey_coSet = 'coSet';
export const getCoSet = async (searchType: string, searchValue: string, sort: number) => {
    const response = await get<coSet[]>("/api/co_set",
        {params: {searchType: searchType, searchValue: searchValue, sort: sort}});
    return response.data;
}

export const deleteAndInsertCoSet = async (coSeq: number) => {
    const response = await post("/api/co_set",undefined,
        {params: {coSeq: coSeq}});
    return response.data;
}

export const deleteCoSet = async (coSeq: number) => {
    const response = await destroy(`/api/co_set/${coSeq}`);
    return response.data;
}