import React, {useState} from "react";
import {Button, Form, Input, message, Modal, Radio, Row, Select, Space, Switch} from "antd";
import {CU_TYPE} from "../constants/company";
import {useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    addUser,
    getCountUserById, getUserBySeq, modifyCurrentUser,
    modifyUser, modifyUserPasswd, queryKey_users,
    updatePasswdRequest, userRequest
} from "../api/userAPI";

import '../styles/addUser.css'
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {authToCuType, cuTypeToAuth, queryKey_whoami, whoAmI} from "../api/loginAPI";
import {checkValidEmail} from "../libs/validateData";
import {getAllCompanies, queryKey_company} from "../api/companyAPI";


const AddUser = () => {

    const formItemLayout = {
        labelCol: {
            xs: { span: 12 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 12 },
            sm: { span: 16 },
        },
    };

    const passwdFormItemLayout = {
        labelCol: {
            xs: { span: 12 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 12 },
            sm: { span: 20 },
        },
    };

    const queryClient = useQueryClient();
    const [form] = Form.useForm();
    const [passwdForm] = Form.useForm();
    const navigate = useNavigate();
    const param = useParams();
    const isNew: boolean = param.id === 'new';
    const [isAdmin, setIsAdmin] = useState(false);
    const [checkedId, setCheckedId] = useState(!isNew);
    const userId = param.id? param.id : '-1';
    const [openModal, setOpenModal] = useState(false);
    const {data: data_whoami} = useQuery([queryKey_whoami], () => whoAmI());
    const {data: data_companies} = useQuery([queryKey_company, ''], () => getAllCompanies(''));
    useQuery([queryKey_users, userId], () => getUserBySeq(parseInt(userId)), {
        enabled: !isNew && (data_companies != null) && (!isNaN(parseInt(userId))) && (parseInt(userId) != -1),
        onSuccess: data => {
            const company = (data_companies != null) ? data_companies.find(com => com.coSeq === data.coSeq) : null;
            form.setFieldsValue({
                cuType: authToCuType(data.authority),
                coName: company ? company.coName : '관리자',
                cuName: data.cuName,
                cuTel: data.cuTel,
                cuId: data.cuId,
                coSeq: data.coSeq,
                cuPasswd: '',
                cuEmail: data.cuEmail,
                cuBlockFlag: data.cuBlockFlag === 'Y'
            });
        }
    });

    const checkDupMut = useMutation((cuId: string) => getCountUserById(cuId), {
        onSuccess: (data: number) => {
            if(data > 0) {
                message.warning('중복된 아이디가 있습니다.');
            } else {
                message.info('사용 가능한 아이디 입니다.');
                setCheckedId(true);
            }
        }
    })

    const addUserMut = useMutation((request: userRequest) => addUser(request), {
        onSuccess: () => {
            message.success('업데이트 완료');
            navigate('/userList');
        },
        onError: (error) => {
            message.error('업데이트 실패');
            console.log(error);
        }
    })

    const modifyUserMut = useMutation((request: userRequest) => modifyUser(Number.parseInt(userId), request), {
        onSuccess: () => {
            message.success('업데이트 완료');
            navigate('/userList');
        },
        onError: (error) => {
            message.error('업데이트 실패');
            console.log(error);
        }
    })

    const modifyCurrentUserMut = useMutation((request: userRequest) => modifyCurrentUser(Number.parseInt(userId), request), {
        onSuccess: () => {
            message.success('업데이트 완료');
            navigate('/userList');
        },
        onError: (error) => {
            message.error('업데이트 실패');
            console.log(error);
        }
    })

    const updatePasswdMut = useMutation((request: updatePasswdRequest) => modifyUserPasswd(Number.parseInt(userId), request), {
        onSuccess: () => {
            message.success('비밀번호 변경 완료');
            setOpenModal(false);
        },
        onError: (error) => {
            message.error('업데이트 실패');
            console.log(error);
        }
    })

    const updatePasswdHandler = (values: any) => {
        if(values.newPassword !== values.confirmPassword) {
            message.warning('비밀번호와 비밀번호 확인란의 값이 다릅니다.');
            return;
        }

        updatePasswdMut.mutate({
            password: '',
            newPassword: values.newPassword,
            confirmPassword: values.confirmPassword
        });
    }

    const submitHandler = (values: any) => {
        if(!checkedId) {
            message.warning('아이디 중복 체크를 해주세요.');
            return;
        }

        if(values.coSeq === 0 && values.cuType === CU_TYPE[1]) {
            message.warning('고객사 입력이 잘못되었습니다.');
            return;
        }

        if(isNew) {
            addUserMut.mutate({
                cuName: values.cuName,
                cuId: values.cuId,
                coSeq: values.coSeq,
                cuPasswd: values.cuPasswd,
                cuTel: values.cuTel,
                cuEmail: values.cuEmail,
                cuBlockFlag: values.cuBlockFlag ? 'Y' : 'N',
                authority: cuTypeToAuth(values.cuType)
            })
        } else {
            if(data_whoami && values.cuSeq == data_whoami.cuSeq) {
                modifyCurrentUserMut.mutate( {
                    cuName: values.cuName,
                    cuId: values.cuId,
                    coSeq: values.coSeq,
                    cuPasswd: values.cuPasswd,
                    cuTel: values.cuTel,
                    cuEmail: values.cuEmail,
                    cuBlockFlag: values.cuBlockFlag ? 'Y' : 'N',
                    authority: cuTypeToAuth(values.cuType)
                })
            } else {
                modifyUserMut.mutate({
                    cuName: values.cuName,
                    cuId: values.cuId,
                    coSeq: values.coSeq,
                    cuPasswd: '',
                    cuTel: values.cuTel,
                    cuEmail: values.cuEmail,
                    cuBlockFlag: values.cuBlockFlag ? 'Y' : 'N',
                    authority: cuTypeToAuth(values.cuType)
                })
            }
        }
    }

    const onCancel = () => {
        navigate('/userList');
    }

    const checkDupId = () => {
        const cuId = form.getFieldValue('cuId');
        if(cuId.length === 0) {
            message.warning('아이디를 입력해주세요.');
        } else {
            checkDupMut.mutate(cuId);
        }
    }

    const onChangeCuType = (value: string) => {
        setIsAdmin(value === CU_TYPE[0]);
        if (value === CU_TYPE[0]) {
            form.setFieldValue('coSeq', 0);
        } else {
            form.setFieldValue( 'coSeq', null);
        }
    }

    const passwdRules =
        [
            {
                required: true,
                message: '비밀번호를 입력해주세요.'
            },
            {
                min: 8,
                max: 20,
                message: '비밀번호는 8자리에서 20자리 사이여야 합니다.'
            },
            {
                pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@!%*#?&]+/,
                message: '비밀번호는 영문,숫자,특수문자가 모두 포함되어야 합니다.'
            }
        ];

    return (
        <div>
        <Form
            {...formItemLayout}
            name="register"
            form={form}
            style={{maxWidth: 1000, minWidth: 1000}}
            scrollToFirstError
            onFinish={submitHandler}
            colon
        >
            <Form.Item
                name="cuType"
                label="권한"
                rules={[
                    {
                        required: true,
                        message: '분류를 입력해주세요.'
                    }
                ]}
                >
                <Radio.Group disabled={!isNew} options={CU_TYPE.map(item => ({value: item, label: item}) )} onChange={(e) => onChangeCuType(e.target.value)}/>
            </Form.Item>
            <Form.Item
                name="coSeq"
                label="고객사"
                rules={[
                    {
                        required: true,
                        message: '고객사를 선택해주세요.'
                    }
                ]}
            >
                <Select style={{width:200}} disabled={!isNew || isAdmin} options={[{value: 0, label: '관리자'}, ...data_companies!!.map(item => ({value: item.coSeq, label: item.coName}) )]}/>
            </Form.Item>
            <Form.Item
                name="cuName"
                label="이름"
                rules={[
                    {
                        required: true,
                        message: '이름을 입력해주세요.'
                    }
                ]}
                >
                <Input className="AddUser-input"/>
            </Form.Item>
            <Form.Item
                name="cuTel"
                label="연락처"
                rules={[
                    {
                        required: true,
                        message: '연락처를 입력해주세요.'
                    }
                ]}
            >
                <Input className="AddUser-input"/>
            </Form.Item>
            <Form.Item
                label="아이디"
                required
            >
                <Row>
                    <Form.Item
                        name="cuId"
                        noStyle
                        rules={[
                            {
                                required: true,
                                message: '아이디를 입력해주세요.'
                            }
                        ]}
                        >
                        <Input className="AddUser-input" disabled={!isNew} onChange={() => setCheckedId(false)} />
                    </Form.Item>
                    <Button style={{marginLeft:10}} onClick={() => checkDupId()} disabled={!isNew||checkedId}>중복확인</Button>
                </Row>
            </Form.Item>
            <Form.Item
                label="비밀번호"
                required={isNew}
                >
                {isNew? (
                <Row>
                    <Form.Item
                        name="cuPasswd"
                        noStyle
                        rules={passwdRules}
                    >
                        <Input.Password className="AddUser-input" autoComplete='new-password' />
                    </Form.Item>
                </Row>) :
                    (<Button onClick={() => setOpenModal(true)}>비밀번호 변경</Button>)
                }
            </Form.Item>
            <Form.Item
                name="cuEmail"
                label="이메일"
                required
                rules={[
                    {
                        validator: (rule, value) => {
                            const errmsg = checkValidEmail(value);
                            if(errmsg.length > 0 ) return Promise.reject(new Error(errmsg));
                            return Promise.resolve();
                        }
                    }
                ]}
                >
                <Input.TextArea className="AddUser-text-area"/>
            </Form.Item>
            <Form.Item
                label="접속 차단"
                name="cuBlockFlag"
                valuePropName='checked'
                initialValue={false}
                >
                <Switch
                    checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}
                    />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
                <Space>
                    <Button type="primary" htmlType="submit" >
                        {isNew? '추가' : '수정'}
                    </Button>
                    <Button onClick={onCancel}>
                        취소
                    </Button>
                </Space>
            </Form.Item>
        </Form>
        <Modal
            open={openModal}
            title='비밀번호 변경'
            onCancel={() => setOpenModal(false)}
            footer={null}
            >
            <Form {...passwdFormItemLayout}
                  form={passwdForm}
                  onFinish={updatePasswdHandler}
            >
                <Form.Item
                    name='newPassword'
                    label='비밀번호'
                    rules={passwdRules}
                >
                    <Input.Password style={{width: '50%', marginLeft:20}}/>
                </Form.Item>
                <Form.Item
                    name='confirmPassword'
                    label='비밀번호 확인'
                    rules={[{
                        required: true,
                        message: '비밀번호를 다시 한번 입력해주세요.'
                    }]}
                    >
                    <Input.Password style={{width: '50%', marginLeft:20}}/>
                </Form.Item>
                <Form.Item style={{margin:0}} wrapperCol={{ offset: 10, span: 16 }}>
                    <Space>
                        <Button type="primary" htmlType="submit" >
                            수정
                        </Button>
                        <Button onClick={() => setOpenModal(false)}>
                            취소
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
        </div>
    );
}



export default AddUser;