import React from 'react';
import {Layout} from "antd";
import MainMenu from "../components/mainMenu";
import MainContent from "../components/mainContent";
import MainHeader from "../components/mainHeader";
import MainFooter from "../components/mainFooter";

import '../styles/main.css'

interface MainProps {
    userId:string;
    isAdmin:boolean;
}

const Main = (props: MainProps) => {
    return (
        <Layout style={{ minHeight: "100vh" }}>
            <MainMenu
                isAdmin={props.isAdmin}
            />
            <Layout className="Main-content-layout">
                <MainHeader userId={props.userId} isAdmin={props.isAdmin}/>
                <MainContent userId={props.userId} isAdmin={props.isAdmin}/>
                <MainFooter/>
            </Layout>
        </Layout>
    );

}

export default Main;