

export const disributePeriod = [
    '1월1차', '1월2차',
    '2월1차', '2월2차',
    '3월1차', '3월2차',
    '4월1차', '4월2차',
    '5월1차', '5월2차',
    '6월1차', '6월2차',
    '7월1차', '7월2차',
    '8월1차', '8월2차',
    '9월1차', '9월2차',
    '10월1차', '10월2차',
    '11월1차', '11월2차',
    '12월1차', '12월2차'
];