import {get, post, put, destroy} from '../api'
import _ from 'lodash'

export type folderCoRel = {
    fldSeq: number;
    fldName: string;
    coNames: string;
}

export type coFolderRel = {
    fldSeq:number;
    fldName:string;
    useCnt:number;
    useDefCnt:number;
}

export type folderRequest = {
    fldSeq:number;
    fldName: string;
}

export type coFolderRequest = {
    coSeq:number;
    fldSeq:number;
}

export const queryKey_folderCoRel = 'folderCoRel';
export const getFolderCoRel = async () => {
    const response = await get<folderCoRel[]>("/api/folder/company");
    return response.data;
}

export const queryKey_coFolderRel = 'coFolderRel';
export const getCoFolderRel = async (coSeq: number, sort: number) => {
    const response = await get<coFolderRel[]>("/api/folder/company/" + coSeq,
        {params: {sort: sort}});
    return response.data;
}

export const queryKey_mapDownList = 'mapDownList';
export const getMapDownList = async (coSeq: number) => {
    const response = await get<coFolderRel[]>(`/api/folder/down/company/${coSeq}`);
    return response.data;
}

export const addFolder = async (params: folderRequest) => {
    const addParams = _.omit(params, 'fldSeq');
    const response = await post<folderRequest>('/api/folder',
        addParams);
    return response.data;
}

export const updateFolder = async (params: folderRequest) => {
    const {fldSeq} = params;
    const updateParams = _.omit(params, 'fldSeq');
    const response = await put<folderRequest>('/api/folder/' + fldSeq,
        updateParams);
    return response.data;
}

export const deleteFolder = async (fldSeq: number) => {
    const response = await destroy('/api/folder/' + fldSeq);
    return response.data;
}

export const insertCoFolder = async (coSeq:number, params: coFolderRequest[]) => {
    const response = await post<coFolderRequest[]>('/api/folder/company/' + coSeq + '/current_month', params);
    return response.data;
}

export const insertCoDefFolder = async (coSeq:number, params: coFolderRequest[]) => {
    const response = await post<coFolderRequest[]>('/api/folder/company/' + coSeq + '/contract', params);
    return response.data;
}