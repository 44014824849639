import axios from "axios";

const apiClient = axios.create({
    timeout: 30000, // 10s
});

// 응답 인터셉터
apiClient.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if(error.response && error.response.status) {
            switch(error.response.status) {
                default:
                    return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    },
);

const {get, post, put, delete: destroy, patch} = apiClient;
export {get, post, put, destroy, patch};