import {configureStore} from "@reduxjs/toolkit";
import fileDownloadReducer from './ducks/downloadFile.duck'

export const store = configureStore({
    reducer: {
        fileDownload: fileDownloadReducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;