import {ColumnsType} from "antd/es/table";
import {Button, DatePicker, Input, Row, Space, Spin, Table, TablePaginationConfig} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {useQuery} from "react-query";
import {getAccessLogPaging, getCountAccessLog, queryKey_accessLog} from "../api/accessLogAPI";
import dayjs from "dayjs";

interface DataType {
    key:string;
    seq:number;
    coName:string;
    cuName:string;
    ip:string;
    regDate:string;
}
const AccessLog = () => {
    const [inputValue, setInputValue] = useState('');
    const [dateInput, setDateInput] = useState(['','']);
    const [searchValue, setSearchValue] = useState('');
    const [searchStDate, setSearchStDate] = useState('');
    const [searchEdDate, setSearchEdDate] = useState('');
    const [pagination, setPagination] = useState<TablePaginationConfig>({pageSize: 10, current: 1});

    const {data, isLoading} = useQuery([queryKey_accessLog, searchValue, searchStDate, searchEdDate, pagination.pageSize, pagination.current],
        () => getAccessLogPaging(searchValue, searchStDate, searchEdDate,
            pagination.pageSize ? pagination.pageSize : 10, pagination.current ? pagination.current : 1));

    useQuery([queryKey_accessLog, searchValue, searchStDate, searchEdDate],
        () => getCountAccessLog(searchValue, searchStDate, searchEdDate).then(value => {
            const pg = {...pagination};
            pg.total = value;
            pg.current = 1;
            setPagination(pg);
        }));

    const columns: ColumnsType<DataType> = [
        {
            align: 'center',
            title: 'No',
            dataIndex: 'key',
            width: '10%'
        },
        {
            align: 'center',
            title: '고객사',
            dataIndex: 'coName'
        },
        {
            align: 'center',
            title: '유저명',
            dataIndex: 'cuName'
        },
        {
            align: 'center',
            title: 'IP',
            dataIndex: 'ip'
        },
        {
            align: 'center',
            title: '접속일자',
            dataIndex: 'regDate'
        }
    ];

    const onSearch = () => {
        setSearchValue(inputValue);
        setSearchStDate(dateInput[0]);
        setSearchEdDate(dateInput[1]);
    }

    const onTableChange = (curPagination: TablePaginationConfig) => {
        const pg = { ...pagination};
        if(pg.current != curPagination.current || pg.pageSize != curPagination.pageSize) {
            pg.current = curPagination.current;
            pg.pageSize = curPagination.pageSize;
            setPagination(pg);
        }
    }

    return (
        <div>
            <Row justify='space-between' style={{marginBottom:5}}>
                <Space wrap>
                    <DatePicker.RangePicker
                        format='YYYY/MM/DD'
                        placeholder={['접속일자 시작', '접속일자 종료']}
                        picker='date'
                        onChange={(values, formatString) => setDateInput(formatString)}
                    />
                    <Input placeholder='업체명'
                           allowClear
                           onChange={e => setInputValue(e.target.value)}
                    />
                    <Button type='primary' onClick={onSearch} icon={<SearchOutlined/>}/>
                </Space>
            </Row>
            <Table
                loading={isLoading ? {indicator: <Spin/>} : false}
                size='small'
                columns={columns}
                pagination={pagination}
                onChange={onTableChange}
                dataSource={
                    data ?
                        (data.map((record) => {
                            return {
                                key: (record.seq).toString(),
                                coName: record.coName,
                                cuName: record.cuName,
                                seq: record.seq,
                                ip: record.ip,
                                regDate: dayjs(record.regDate).format('YYYY/MM/DD HH:mm:ss')
                            }
                        })) : undefined}
            />
        </div>
    );
}

export default AccessLog;