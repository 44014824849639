import {Content} from "antd/es/layout/layout";
import {theme} from "antd";
import {Route, Routes, Navigate, useNavigate} from "react-router-dom";
import CompanyList from "./companyList";
import DistributeFolderList from "./distributeFolderList";
import AddCompany from "./addCompany";
import React, {useEffect, useState} from "react";

import '../styles/main.css'
import DistributeCustFolderList from "./distributeCustFolderList";
import DistributeSetting from "./distributeSetting";
import DistributeHistory from "./distributeHistory";
import AccessLog from "./accessLog";
import MapDown from "./mapDown";
import ModiCurrentUser from "./modiCurrentUser";
import UserList from "./userList";
import AddUser from "./addUser";

interface MainContentProps {
    userId:string
    isAdmin:boolean
}


const MainContent = (props: MainContentProps) => {
    const {
        token: {colorBgContainer},
    } = theme.useToken();

    return (
        <Content style={{ margin: "0 16px" }}>
            <div
                style={{
                    margin: "16px 0",
                    padding: 24,
                    minHeight: 360,
                    background: colorBgContainer
                }}
            >
                <Routes>
                    <Route path="/" element={DefaultPageWrapper(props.isAdmin)}/>
                    <Route path="/companyList" element={<Auth Component={<CompanyList/>} isAdmin={props.isAdmin} isAdminPage={true}/>}/>
                    <Route path="/userList" element={<Auth Component={<UserList/>} isAdmin={props.isAdmin} isAdminPage={true}/>}/>
                    <Route path="/modifyCompany/:id" element={<Auth Component={<AddCompany/>} isAdmin={props.isAdmin} isAdminPage={true}/>}/>
                    <Route path="/modifyUser/:id" element={<Auth Component={<AddUser/>} isAdmin={props.isAdmin} isAdminPage={true}/>}/>
                    <Route path="/distributeFolderList" element={<Auth Component={<DistributeFolderList/>} isAdmin={props.isAdmin} isAdminPage={true} />}/>
                    <Route path="/distributeCustFolderList" element={<Auth Component={<DistributeCustFolderList/>} isAdmin={props.isAdmin} isAdminPage={true}/>}/>
                    <Route path="/distributeSetting" element={<Auth Component={<DistributeSetting/>} isAdmin={props.isAdmin} isAdminPage={true}/>}/>
                    <Route path="/distributeHistory" element={<Auth Component={<DistributeHistory/>} isAdmin={props.isAdmin} isAdminPage={true}/>}/>
                    <Route path="/accessLog" element={<Auth Component={<AccessLog/>} isAdmin={props.isAdmin} isAdminPage={true}/>}/>
                    <Route path="/mapDown" element={<Auth Component={<MapDown/>} isAdmin={props.isAdmin} isAdminPage={false}/>}/>
                    <Route path="/modiCurrentUser" element={<Auth Component={<ModiCurrentUser/>} isAdmin={props.isAdmin} isAdminPage={false}/>}/>
                    <Route path="/*" element={DefaultPageWrapper(props.isAdmin)}/>
                </Routes>
            </div>
        </Content>
    );
}

const DefaultPageWrapper = (isAdmin:boolean) => {
    return isAdmin ? (
        <Navigate to="/distributeFolderList"/>
    ) : (
        <Navigate to="/mapDown"/>
    );
};

type AuthParam = {
    Component:any,
    isAdmin:boolean,
    isAdminPage:boolean
}

const Auth:React.FC<any> = (param: AuthParam) => {
    const [component, setComponent] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        if(param.isAdminPage && !param.isAdmin) {
            window.location.replace('/');
        } else {
            setComponent(param.Component);
        }
    }, [param.isAdminPage, param.Component, navigate, param.isAdmin]);

    return component
}

export default MainContent;