import React, {useState} from "react";
import {Button, Col, DatePicker, Form, Input, message, Row, Space, Switch} from "antd";
import {disributePeriod} from "../constants/distribute";
import {useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import dayjs from "dayjs";
import CheckableTag from "antd/es/tag/CheckableTag";

import '../styles/addUser.css'
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {
    addCompany,
    companyRequest,
    getCompanyBySeq,
    getCountCompanyByName,
    modifyCompany,
    queryKey_company
} from "../api/companyAPI";

interface DistDatesGroupProp {
    value?: string[];
    onChange?: (value: string[]) => void;
}
const DistDatesGroup: React.FC<DistDatesGroupProp> = ({value: selectedTags = [], onChange}:DistDatesGroupProp) => {
    const onChangeTag = (tag: string, checked: boolean) => {
        const newArr = checked ? [...selectedTags, tag] :
            selectedTags.filter((t) => t !== tag);

        onChange?.(newArr);
    }

    return (
        <span>
                <Row>
                    {disributePeriod.map((value, index) => {
                        if(value.includes('1차')) {
                            return (
                                <Col key={index} span={2}>
                                    <CheckableTag
                                        key={value}
                                        checked={selectedTags.includes(value)}
                                        onChange={checked => onChangeTag(value, checked)}
                                        className='AddUser-distDates-button'
                                    >
                                        {value}
                                    </CheckableTag>
                                </Col>
                            );
                        }
                    })}
                </Row>
                <Row>
                    {disributePeriod.map((value, index) => {
                        if(value.includes('2차')) {
                            return (
                                <Col key={index} span={2}>
                                    <CheckableTag
                                        key={value}
                                        checked={selectedTags.includes(value)}
                                        onChange={checked => onChangeTag(value, checked)}
                                        className='AddUser-distDates-button'
                                    >
                                        {value}
                                    </CheckableTag>
                                </Col>
                            );
                        }
                    })}
                </Row>
        </span>
    )
}

const AddCompany = () => {

    const formItemLayout = {
        labelCol: {
            xs: { span: 12 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 12 },
            sm: { span: 16 },
        },
    };

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const param = useParams();
    const isNew: boolean = param.id === 'new';
    const [checkedName, setCheckedName] = useState(!isNew);
    const companyId = param.id? param.id : '-1';
    useQuery([queryKey_company, companyId], () => getCompanyBySeq(parseInt(companyId)), {
        enabled: !isNew && !isNaN(parseInt(companyId)) && (parseInt(companyId) != -1),
        onSuccess: data => {
            form.setFieldsValue({
                coName: data.coName,
                coContact: data.coContact,
                coRange: [dayjs(data.coContractSt), dayjs(data.coContractEd)],
                coDistDates: data.coDistDates?.split(','),
                coBlockFlag: data.coBlockFlag === 'Y'
            });
        }
    });

    const checkDupMut = useMutation((coName: string) => getCountCompanyByName(coName), {
        onSuccess: (data: number) => {
            if(data > 0) {
                message.warning('중복된 업체명이 있습니다.');
            } else {
                message.info('사용 가능한 업체명 입니다.');
                setCheckedName(true);
            }
        }
    })

    const addCompanyMut = useMutation((request: companyRequest) => addCompany(request), {
        onSuccess: () => {
            message.success('업데이트 완료');
            navigate('/companyList');
        },
        onError: (error) => {
            message.error('업데이트 실패');
            console.log(error);
        }
    })

    const modifyCompanyMut = useMutation((request: companyRequest) => modifyCompany(Number.parseInt(companyId), request), {
        onSuccess: () => {
            message.success('업데이트 완료');
            navigate('/companyList');
        },
        onError: (error) => {
            message.error('업데이트 실패');
            console.log(error);
        }
    })

    const submitHandler = (values: any) => {
        if(!checkedName) {
            message.warning('업체명 중복 체크를 해주세요.');
            return;
        }

        if(isNew) {
            addCompanyMut.mutate({
                coName: values.coName,
                coContact: values.coContact,
                coEmail: values.coEmail,
                coContractSt: values.coRange[0].toDate(),
                coContractEd: values.coRange[1].toDate(),
                coBlockFlag: values.coBlockFlag ? 'Y' : 'N',
                coDistDates: values.coDistDates ? values.coDistDates.join(',') : '',
            })
        } else {
            modifyCompanyMut.mutate({
                coName: values.coName,
                coContact: values.coContact,
                coEmail: values.coEmail,
                coContractSt: values.coRange[0].toDate(),
                coContractEd: values.coRange[1].toDate(),
                coBlockFlag: values.coBlockFlag ? 'Y' : 'N',
                coDistDates: values.coDistDates ? values.coDistDates.join(',') : '',
            })
        }
    }

    const onCancel = () => {
        navigate('/companyList');
    }

    const checkDupName = () => {
        const coName = form.getFieldValue('coName');
        if(coName.length === 0) {
            message.warning('업체명을 입력해주세요.');
        } else {
            checkDupMut.mutate(coName);
        }
    }

    return (
        <div>
        <Form
            {...formItemLayout}
            name="register"
            form={form}
            style={{maxWidth: 1000, minWidth: 1000}}
            scrollToFirstError
            onFinish={submitHandler}
            colon
        >
            <Form.Item
                label="업체명"
                required
            >
            <Row>
                <Form.Item
                    name="coName"
                    noStyle
                    rules={[
                        {
                            required: true,
                            message: '업체명을 입력해주세요.'
                        }
                    ]}
                >
                    <Input className="AddUser-input" disabled={!isNew}/>
                </Form.Item>
                <Button style={{marginLeft:10}} onClick={() => checkDupName()} disabled={!isNew||checkedName}>중복확인</Button>
            </Row>
            </Form.Item>
            <Form.Item
                name="coContact"
                label="담당자"
                rules={[
                    {
                        required: true,
                        message: '담당자를 입력해주세요.'
                    }
                ]}
            >
                <Input className="AddUser-input"/>
            </Form.Item>
            <Form.Item
                label="계약기간"
                name="coRange"
                rules={[
                    {
                        required: true,
                        message: '계약기간을 입력해주세요.'
                    }
                ]}
                >
                <DatePicker.RangePicker format='YYYY-MM-DD' picker="date" placeholder={["시작날짜", "종료날짜"]}
                                        className="AddCompany-input"/>
            </Form.Item>
            <Form.Item
                name="coDistDates"
                label="배포 일정"
                valuePropName='value'
            >
                <DistDatesGroup/>
            </Form.Item>
            <Form.Item
                label="접속 차단"
                name="coBlockFlag"
                valuePropName='checked'
                initialValue={false}
                >
                <Switch
                    checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}
                    />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
                <Space>
                    <Button type="primary" htmlType="submit" >
                        {isNew? '추가' : '수정'}
                    </Button>
                    <Button onClick={onCancel}>
                        취소
                    </Button>
                </Space>
            </Form.Item>
        </Form>
        </div>
    );
}



export default AddCompany;