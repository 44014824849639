import React, {useState} from 'react';
import {Button, message, Popconfirm, Row, Segmented, Space, Spin, Table, Typography} from "antd";
import type {ColumnsType} from "antd/es/table";
import {DeleteOutlined, EditOutlined, UserAddOutlined} from "@ant-design/icons";
import {useMutation, useQuery, useQueryClient} from "react-query";
import { SegmentedValue } from 'antd/es/segmented';
import Search from "antd/es/input/Search";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {deleteCompany, getCompanies, queryKey_company} from "../api/companyAPI";

const customerContractTypes = ['진행중', '계약종료'];
// const searCompoOptions = ['전체', ...CO_TYPE];


// const searchCombo = (<Select
//     style={{width:80}}
//     defaultValue={'전체'}
//     options={(searCompoOptions).map(item => ({value: item, label: item}))}/>);

interface DataType {
    coSeq: string;
    coName: string;
    coContact: string;
    coContract: string;
    regDate: string;
}

const CompanyList = () => {
    const queryClient = useQueryClient();
    const [isContract, setIsContract] = useState(true);
    const [searchKeyword, setSearchKeyword] = useState("");
    const {data, isLoading} = useQuery([queryKey_company, isContract, searchKeyword], () => getCompanies(isContract, searchKeyword));

    const deleteCompanyMut = useMutation((coSeq: number) => deleteCompany(coSeq),{
        onSuccess: () => {
            queryClient.invalidateQueries([queryKey_company, isContract, searchKeyword]);
            message.success('업데이트 완료');
        },
        onError: (error) => {
            message.error('업데이트 실패');
            console.log(error);
        }
    })

    const columns: ColumnsType<DataType> = [
        {
            align: 'center',
            title: '고객사',
            dataIndex: 'coName',
            key: 'coName'
        },
        {
            align: 'center',
            title: '담당자',
            dataIndex: 'coContact',
            key: 'coContact'
        },
        {
            align: 'center',
            title: '계약기간',
            dataIndex: 'coContract',
            key: 'coContract'
        },
        {
            align: 'center',
            title: '등록일',
            dataIndex: 'regDate',
            key: 'regDate'
        },
        {
            align: 'center',
            title: '수정',
            key: 'modify',
            render: (record) => (
                <Link to={`/modifyCompany/${record.coSeq}`}><EditOutlined/></Link>
            )
        },
        {
            align: 'center',
            title: '삭제',
            key: 'delete',
            render: (record) => (
                <Popconfirm title="삭제하시겠습니까?" onConfirm={() => deleteCompanyMut.mutate(record.coSeq)}>
                    <Typography.Link><DeleteOutlined/></Typography.Link>
                </Popconfirm>
            )
        }
    ]

    const onChangeContractType = (value: SegmentedValue) => {
        setIsContract(value.toString() === customerContractTypes[0]);
    }

    const onSearch = (value: string) => {
        setSearchKeyword(value);
    }

    // if(userEditMode == UserEditMode.Add) {
    //    // return <AddCompany />;
    // } else if(userEditMode == UserEditMode.Edit) {
    //   //  return <AddCompany />;
    // }

    return (
        <div>
            <Row justify="space-between" style={{marginBottom:5}}>
                <Space>
                    <Segmented
                        size="middle"
                        options={customerContractTypes}
                        onChange={onChangeContractType}
                    />
                    <Search
                        allowClear
                        placeholder="고객사/담당자 검색"
                        size="middle"
                        onSearch={onSearch}
                    />
                </Space>
                <Link to={'/modifyCompany/new'}>
                    <Button
                        type="primary"
                        icon={<UserAddOutlined/>}>
                        고객사 추가
                    </Button>
                </Link>
            </Row>
            <Table
                loading={isLoading ? {indicator: <Spin/>} : false}
                size="middle"
                rowKey={(record) => record.coSeq }
                columns={columns} dataSource={
                data && data.map((value) => {
                return {
                    coSeq: value.coSeq.toString(),
                    coName: value.coName,
                    coContact: value.coContact,
                    coContract: dayjs(value.coContractSt).format('YYYY/MM/DD') + ' ~ ' +
                        dayjs(value.coContractEd).format('YYYY/MM/DD'),
                    regDate: dayjs(value.regDate).format('YYYY/MM/DD')
                }})}
            />
        </div>
    );
}

export default CompanyList;