import React, {useEffect, useState} from 'react';
import {ColumnsType} from "antd/es/table";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    folderRequest,
    getFolderCoRel,
    updateFolder,
    deleteFolder,
    addFolder,
    queryKey_folderCoRel
} from "../api/folderAPI";
import {Button, Form, message, Popconfirm, Table, Typography} from "antd";
import EditableCell from "./common/editableCell";

interface DataType {
        key: string;
    fldSeq: number;
    fldName: string;
    coNames: string;
}

const DistributeFolderList = () => {
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const [editingKey, setEditingKey] = useState('');
    const {data} = useQuery([queryKey_folderCoRel], () => getFolderCoRel());

    useEffect(() => {
        queryClient.invalidateQueries(queryKey_folderCoRel);
    },[]);

    const addFolderMut = useMutation((request: folderRequest) => addFolder(request), {
        onSuccess: () => {
            message.success('업데이트 완료');
            queryClient.invalidateQueries(queryKey_folderCoRel);
        },
        onError: (error) => {
            message.error('업데이트 실패');
            console.log(error);
        }
    });

    const updateFolderMut = useMutation((request: folderRequest) => updateFolder(request), {
        onSuccess: () => {
            message.success('업데이트 완료');
            queryClient.invalidateQueries(queryKey_folderCoRel);
        },
        onError: (error) => {
            message.error('업데이트 실패');
            console.log(error);
        }
        });

    const delFolderMut = useMutation((fldSeq: number) => deleteFolder(fldSeq), {
        onSuccess: () => {
            message.success('업데이트 완료');
            queryClient.invalidateQueries(queryKey_folderCoRel);
        },
        onError: (error) => {
            message.error('업데이트 실패');
            console.log(error);
        }
    });

    const isEditing = (record: DataType) => record.key === editingKey;
    const isAdding = (record: DataType) => record.key === '-1';

    const edit = (record: Partial<DataType> & { key: React.Key }) => {
        form.setFieldsValue({ fldName:'', ...record });
        setEditingKey(record.key);
    };

    const onDelete = (fldSeq: number) => {
        try {
            delFolderMut.mutate(fldSeq);
        } catch (errInfo) {
            console.log(errInfo);
        }
    };

    const save = async (fldSeq: number) => {
        try {
            const row = (await form.validateFields()) as DataType;
            if(data) {
                if((fldSeq != -1) && data.find((record) => record.fldSeq === fldSeq)!!.fldName === row.fldName) {
                    message.info('변경사항이 없습니다.');
                    return;
                }

                const fldName = row.fldName.trim();

                if(data.some((record) => record.fldName.toLowerCase() === fldName.toLowerCase())) {
                    message.error("중복된 폴더가 이미 있습니다.");
                    return;
                }

                if(!fldName.endsWith("/")) {
                    message.error("경로의 끝은 /문자가 되어야 합니다.");
                    form.setFieldValue('fldName', fldName + '/');
                    return;
                }

                if(!fldName.startsWith("/SKMap/")) {
                    message.error("경로는 /SKMap/으로 시작해야 합니다.");
                    form.setFieldValue('fldName', ('/SKMap/' + fldName).replace('//', '/'));
                    return;
                }

                if(fldSeq != -1) {
                    updateFolderMut.mutate({fldSeq: fldSeq, fldName: fldName});
                } else if(fldSeq == -1) {
                    addFolderMut.mutate({fldSeq: fldSeq, fldName: fldName});
                }
            }
            setEditingKey('');
        } catch (errInfo) {
            console.log(errInfo);
        }
    };

    const cancel = () => {
        setEditingKey('');
    }

    const addItem = () => {
        form.setFieldsValue({key: '-1', fldName: '/SKMap/'});
        setEditingKey('-1');
    }

    const columns: ColumnsType<DataType> = [
        {
            align: 'center',
            title: 'No',
            dataIndex: 'key',
            width: '5%'
        },
        {
            title: '폴더',
            dataIndex: 'fldName',
            width: '35%',
            onCell: (record: DataType) => ({
                record,
                inputType: 'text',
                dataIndex: 'fldName',
                title: '폴더',
                editing: isEditing(record)
            })
        },
        {
            title: '고객사',
            dataIndex: 'coNames',
            width: '45%'
        },
        {
            align: 'center',
            title: '편집',
            dataIndex: 'edit',
            render: (_:any, record:DataType) => {
                const editable = isEditing(record);
                const cancelMsg = isAdding(record)? '추가를 취소하시겠습니까?' : '수정을 취소하시겠습니까?';
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.fldSeq)}
                            style={{ marginRight: 8 }}>
                          저장
                        </Typography.Link>
                        <Popconfirm title={cancelMsg} onConfirm={cancel}>
                          <a>취소</a>
                        </Popconfirm>
                    </span>
                    ) : (
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            <EditOutlined/>
                        </Typography.Link>
                    );
            }
        },
        {
            align: 'center',
            title: '삭제',
            dataIndex: 'delete',
            render: (_, record) =>
                <Popconfirm title="삭제하시겠습니까?" onConfirm={() => onDelete(record.fldSeq)}>
                    <Typography.Link disabled={editingKey !== ''}>
                        <DeleteOutlined/>
                    </Typography.Link>
                </Popconfirm>
        }
    ];

    const getData = () => {
        if(data) {
            const newData = data.sort((a, b) => a.fldName.localeCompare(b.fldName, 'euc-kr'))
                .map((value, index) => {
                    return {
                        key: (index + 1).toString(),
                        fldSeq: value.fldSeq,
                        fldName: value.fldName,
                        coNames: value.coNames
                    }});

            return editingKey === '-1' ? ([
                ...newData, {key: '-1', fldSeq: -1, fldName:'', coNames:''}]) : newData;
        }
    }

    return (
        <div>
        <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell
                    }
                }}
                size="small"
                columns={columns}
                pagination={false}
                dataSource={getData()}
            />
            <Button type='dashed' onClick={() => addItem()} block icon={<PlusOutlined/>} disabled={editingKey !== ''} style={{margin:5}}>
                폴더 추가
            </Button>
            <hr/>
            <li>폴더 설정은 폴더 양쪽에 '/'를 반드시 표시하여야 합니다.</li>
            <li>중복된 폴더가 있는지 확인 후 업데이트 바랍니다.</li>
        </Form>
        </div>
    );
}

export default DistributeFolderList;