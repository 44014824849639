import {get} from '../api'

export type downLog = {
    coName: string;
    cuName: string;
    fldName: string;
    seq: number;
    ip: string;
    regDate: Date;
}

export const queryKey_downLog = 'downLog';

export const getDownLog = async (searchValue: string, searchStDate: string, searchEdDate: string) => {
    const response = await get<downLog[]>("/api/down_log",
        {params: {searchValue: searchValue, searchStDate: searchStDate, searchEdDate: searchEdDate}});
    return response.data;
}

export const getCountDownLog = async (searchValue: string, searchStDate: string, searchEdDate: string) => {
    const response = await get<number>("/api/down_log/count",
        {params: {searchValue: searchValue, searchStDate: searchStDate, searchEdDate: searchEdDate}});
    return response.data;
}

export const getDownLogPaging = async (searchValue: string, searchStDate: string, searchEdDate: string, pageSize: number, curPage: number) => {
    const response = await get<downLog[]>("/api/down_log/page",
        {params: {searchValue: searchValue, searchStDate: searchStDate, searchEdDate: searchEdDate, pageSize: pageSize, curPage: curPage}});
    return response.data;
}