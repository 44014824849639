import {Avatar, Dropdown, MenuProps, theme} from "antd";
import {DownOutlined, InfoCircleOutlined, LogoutOutlined} from "@ant-design/icons";
import {Header} from "antd/es/layout/layout";
import React from "react";
import {queryKey_whoami, requestLogout} from "../api/loginAPI";
import {useQueryClient} from "react-query";

import '../styles/main.css'

interface MainHeaderProps {
    userId:string
    isAdmin:boolean
}

const MainHeader = (props:MainHeaderProps) => {
    const queryClient = useQueryClient();

    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const onLogout = () => {
        requestLogout();
        queryClient.invalidateQueries([queryKey_whoami]);
        window.location.replace('/');
    }

    if(props.isAdmin) {
        const items: MenuProps['items'] = [
            {
                label: <a onClick={onLogout}><LogoutOutlined/> 로그아웃</a>,
                key: 'logout'
            }
        ];

        return (
            <Header style={{ padding: 0, background: colorBgContainer }}>
                <div className="Main-content-header">
                    <Dropdown menu={{items}} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                            <Avatar style={{backgroundColor: "orangered"}}>{props.userId.at(0)}</Avatar> {props.userId} &nbsp;
                            <DownOutlined/>
                        </a>
                    </Dropdown>
                </div>
            </Header>
        );
    }

    const items: MenuProps['items'] = [
        {
            label: <a href="/modiCurrentUser"><InfoCircleOutlined/> 정보수정</a>,
            key: 'modiCurrentUser'
        },
        {
            label: <a onClick={onLogout}><LogoutOutlined/> 로그아웃</a>,
            key: 'logout'
        }
    ];

    return (
        <Header style={{ padding: 0, background: colorBgContainer }}>
            <div className="Main-content-header">
                <Dropdown menu={{items}} trigger={['click']}>
                    <a onClick={(e) => e.preventDefault()}>
                        <Avatar style={{backgroundColor: "navy"}}>{props.userId.at(0)}</Avatar> {props.userId} &nbsp;
                        <DownOutlined/>
                    </a>
                </Dropdown>
            </div>
        </Header>
    );
}

export default MainHeader;