import {Button, Checkbox, message, Modal, Popconfirm, Row, Select, Space, Spin, Table, Typography} from "antd";
import Search from "antd/es/input/Search";
import React, {useState} from "react";
import {disributePeriod} from "../constants/distribute";
import {ColumnsType} from "antd/es/table";
import {PlayCircleOutlined, StopOutlined} from "@ant-design/icons";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {deleteAndInsertCoSet, deleteCoSet, getCoSet, queryKey_coSet} from "../api/cosetAPI";
import dayjs from "dayjs";
import {sendDistMail} from "../api/downloadAPI";


interface DataType {
    key: string;
    coSeq: number;
    coName: string;
    distDate: string;
    distStateText: string;
    regDate: string;
    checked: boolean;
}

const DistributeSetting = () => {
    const queryClient = useQueryClient();
    const [sort, setSort] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [searchType, setSearchType] = useState('');
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const {data, isLoading} = useQuery([queryKey_coSet, searchType, searchValue, sort], () => getCoSet(searchType, searchValue, sort));

    const [sendEmail, setSendEmail] = useState(false);
    const [distModalOpen, setDistModalOpen] = useState(false);
    const [stopModalOpen, setStopModalOpen] = useState(false);

    const deleteAndInsertCoSetMut = useMutation((coSeq: number) => deleteAndInsertCoSet(coSeq), {
        onSuccess: (data, variables) => {
            message.success('업데이트 완료');
            if(sendEmail) {
                sendEmailMut.mutate(variables);
            }
            queryClient.invalidateQueries([queryKey_coSet, searchType, searchValue, sort]);
        },
        onError: (error) => {
            message.error('업데이트 실패');
            console.log(error);
        }
    });

    const deleteCoSetMut = useMutation((coSeq: number) => deleteCoSet(coSeq), {
        onSuccess: () => {
            queryClient.invalidateQueries([queryKey_coSet, searchType, searchValue, sort]);
            message.success('업데이트 완료');
        },
        onError: (error) => {
            message.error('업데이트 실패');
            console.log(error);
        }
    });

    const sendEmailMut = useMutation((coSeq: number) => sendDistMail(coSeq), {
        onSuccess: () => {
            message.success('메일전송 완료')
        },
        onError: () => {
            message.error('메일전송 실패')
        }
    })

    const onDistStart = () => {
        if(selectedRowKeys.length === 0) {
            message.warning('선택된 배포 항목이 없습니다.');
        } else {
            selectedRowKeys.forEach(value => {
                const coSeq = Number.parseInt(value.toString());
                deleteAndInsertCoSetMut.mutate(coSeq);
            });
        }
        setDistModalOpen(false);
    }

    const onDistStop = () => {
        if(selectedRowKeys.length === 0) {
            message.warning('선택된 배포 항목이 없습니다.');
        } else {
            selectedRowKeys.forEach(value => {
                deleteCoSetMut.mutate(Number.parseInt(value.toString()));
            });
        }
        setStopModalOpen(false);
    }

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(selectedRowKeys);
        },
        selectedRowKeys: selectedRowKeys
    };

    const columns: ColumnsType<DataType> = [
        {
            align: 'center',
            title: 'No',
            dataIndex: 'key',
            width: '5%'
        },
        {
            align: 'center',
            title: '고객사',
            dataIndex: 'coName',
            render: (value) => {
                return <div style={{color:'darkblue'}}>{value}</div>
            }
        },
        {
            align: 'center',
            title: '배포설정',
            dataIndex: 'distDate',
        },
        {
            align: 'center',
            title: '배포시작',
            dataIndex: 'distStateText',
            render: (value, record) => {
                const color = value === '배포재시작' ? 'orange' : 'red';

                return <a style={{color:color}} onClick={() => {
                    setSelectedRowKeys([record.coSeq]);
                    setDistModalOpen(true);
                }}><u>{value}</u></a>;
            }
        },
        {
            align: 'center',
            title: '메일발송',
            render: (record) => (
                <Popconfirm title="메일을 보내시겠습니까?" onConfirm={() => sendEmailMut.mutate(record.coSeq)}>
                    <Typography.Link>발송</Typography.Link>
                </Popconfirm>
            )
        },
        {
            align: 'center',
            title: '배포일자',
            dataIndex: 'regDate',
        }
    ];

    return (
        <div>
        <Row justify="space-between" style={{marginBottom:5}}>
            <Space>
                <Search
                    allowClear
                    placeholder="고객사/담당자 검색"
                    size="middle"
                    onSearch={value => setSearchValue(value)}
                />
            </Space>
            <Space>
                <Select
                    style={{width:100}}
                    defaultValue='0'
                    options={ [{label:'전체', value:'0'},
                        ...disributePeriod.map((value) => {
                            return { label:value, value:value }
                    })]}
                    onSelect={value => {
                        setSearchType(value === '0' ? '' : value);
                        setSelectedRowKeys([]);
                    }}
                />
                <Select
                    style={{width:120}}
                    defaultValue='0'
                    options={[{label:'전체', value:'0'}, {label:'배포시작', value:'1'}, {label:'배포재시작', value:'2'}]}
                    onSelect={value => {
                        setSort( parseInt(value));
                        setSelectedRowKeys([]);
                    }}
                />
            </Space>
        </Row>
        <Table
            rowSelection={{
                type: 'checkbox',
                ...rowSelection
            }}
            loading={isLoading ? {indicator: <Spin/>} : false}
            pagination={false}
            size="small"
            columns={columns}
            rowKey="coSeq"
            dataSource={
                data ?
                    (data.map((record, index) => {
                        const time = dayjs(record.regDate);
                        return {
                            key: (index + 1).toString(),
                            coSeq: record.coSeq,
                            coName: record.coName,
                            distStateText: record.distStateText,
                            distDate: record.distDate,
                            regDate: time.isValid() ? time.format('YYYY/MM/DD HH:mm:ss') : '',
                            checked: false
                        }
                    })) : undefined}
            />
        <Row justify='end' style={{marginTop:5}}>
            <Space wrap>
                <Button type='primary' style={{backgroundColor:"coral"}} icon={<StopOutlined/>} onClick={() => setStopModalOpen(true)}>선택 항목 중지</Button>
                <Button type='primary' icon={<PlayCircleOutlined/>} onClick={() => setDistModalOpen(true)}>선택 항목 시작</Button>
            </Space>
        </Row>
            <Modal
                open={distModalOpen}
                title='배포 시작'
                onOk={() => onDistStart()}
                onCancel={() => setDistModalOpen(false)}
                footer={[
                    <Button key='back' onClick={() => setDistModalOpen(false)}>
                        취소
                    </Button>,
                    <Button key='submit' type='primary' onClick={() => onDistStart()}>
                        시작
                    </Button>,
            ]}>
                배포를 시작하시겠습니까? <br/>
                <Checkbox checked={sendEmail} onChange={e => setSendEmail(e.target.checked)}>
                    메일 전송
                </Checkbox>
            </Modal>
            <Modal
                open={stopModalOpen}
                title='배포 중단'
                onOk={() => onDistStop()}
                onCancel={() => setStopModalOpen(false)}
                footer={[
                    <Button key='back' onClick={() => setStopModalOpen(false)}>
                        취소
                    </Button>,
                    <Button key='submit' type='primary' onClick={() => onDistStop()}>
                        확인
                    </Button>,
                ]}>
                배포를 중단하시겠습니까?
            </Modal>
        </div>
    );
}

export default DistributeSetting;